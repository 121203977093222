import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import EastIcon from "@mui/icons-material/East";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import TextField from '@mui/material/TextField';
import "./PrescreenBulkModal.scss";
import Select from "react-select";
import cogoToast from "cogo-toast";
import { useSelector } from "react-redux";
import { uploadFileV2 } from "../../../services/file";
import { getPreScreening } from "../../../services/prescreening";
import CircularProgress from "@mui/material/CircularProgress";

const PrescreenBulkModal = ({ open, setPrescreenBulkUpload, bulkUploadPayload, bulkStatus, setBulkStatus, setBulkUploadData }) => {
    const [files, setFiles] = useState();
    const [checked, setChecked] = useState(true);
    const [canEdit, setCanEdit] = useState(false);
    const [timeWindow, setTimeWindow] = useState();
    const [startTimeStamp, setStartTimeStamp] = useState();
    const [lastAllowedTimeStamp, setLastAllowedTimeStamp] = useState();
    const [testValidity, setTestValidity] = useState();
    const [prescreenData, setPrescreenData] = useState();
    const [loader, setLoader] = useState(false);
    const user = useSelector(state => state?.user);
    const clientId = useSelector(state => state?.selected_client?.id);
    const token = useSelector(state => state?.auth?.access_token);
    const socket = useRef(WebSocket);
    const prod = process.env.REACT_APP_STAGE === "prod" ? true : false;
    const reactAppStage = process.env.REACT_APP_STAGE;

    const testValidityOptions = Array?.from(Array(7)?.keys())?.map((num) => ({ label: num + 1 + `${num + 1 == 1 ? " day" : " days"}`, value: num + 1 }));

    const handleClose = () => {
        setPrescreenBulkUpload(false);
    }

    const isValidFileUploaded = (file) => {
        const validExtensions = ['csv', 'xlsx', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet']
        const fileExtension = file?.type?.split('/')[1]
        return validExtensions?.includes(fileExtension)
    }

    const handleFileUpload = (e) => {
        const file = e?.target?.files[0];
        if (!isValidFileUploaded(file)) {
            cogoToast.error("Please upload a valid file", { position: 'top-right' });
        } else if (file !== undefined) {
            setFiles(file);
        }
        e.target.value = "";
    };

    const handleRemoveFile = () => {
        setFiles(null);
    }

    const handleCheckBox = (value) => {
        setChecked(value);
    };

    const handleRadioOptionChange = (status) => {
        if (status === true) {
            setCanEdit(true)
        } else {
            setCanEdit(false)
        }
    }

    const handleTestValidityChange = (e) => {
        setTestValidity(e);
    }

    const handleStartTimeStamp = (date) => {
        setStartTimeStamp((date.getTime() / 1000))
    }

    const handleLastSubmissionTimestamp = (date) => {
        setLastAllowedTimeStamp((date.getTime() / 1000))
    }

    const handlePreScreenTimeWindow = (e) => {

        const re = /^[0-9\b]+$/;

        const { name, value } = e.target;
        if (value === "" || re?.test(value)) {
            setTimeWindow(e?.target?.value);
        }
    }

    const handleSubmit = () => {
        if (canEdit) {
            if (!startTimeStamp) {
                cogoToast.error("Please select start timestamp")
                return;
            }

            if (!lastAllowedTimeStamp) {
                cogoToast.error("Please select last timestamp")
                return;
            }

            if (!timeWindow) {
                cogoToast.error("Please enter time window")
                return;
            }
        } else if (!canEdit) {
            if (!testValidity) {
                cogoToast.error("Please select test link validity")
                return;
            }
        }

        if (files) {
            setLoader(true);
            uploadFile(files);
        } else {
            cogoToast.error("Please choose a file to be uploaded");
        }
    }

    useEffect(async () => {
        let payload = {
            client_id: clientId,
        }
        const res = await getPreScreening(bulkUploadPayload?.position?.original?.id, payload);
        setPrescreenData(res?.data?.length > 0 ? res?.data?.[0] : {})
    }, [])

    useEffect(() => {
        /* Socket connect event */
        // socket.current = new WebSocket(prod ? 'wss://t0rzjb9iel.execute-api.ap-south-1.amazonaws.com/production/' 
        // : 'wss://n9m42x6yk7.execute-api.ap-south-1.amazonaws.com/test');
       
        socket.current = new WebSocket('wss://t0rzjb9iel.execute-api.ap-south-1.amazonaws.com/production/');
        socket.current.onopen = function (e) {
            console.log("socket on connected");
        };

        /* Socket close event */
        socket.current.onclose = function (event) {
            console.log("socket closed", event);
            if (event.wasClean) {
                console.log(
                    `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
                );
            } else {
                console.log(`[close] Connection died; code=${event.code}`);
            }
        };

        /* Socket error event */
        socket.current.onerror = function (error) {
            console.log(`[error] ${JSON.stringify(error)}`);
        };

    }, []);

    const uploadFile = async (files) => {

        try {
            const response = await uploadFileV2(files);
            const uploadedFileURL = response?.data?.fields["x-amz-meta-internal-key"];
            const current_user = {
                id: user?.id,
                email: user?.email
            };
            let payload = {
                action: "createInterview",
                file_name: uploadedFileURL,
                client_id: clientId,
                current_user: current_user,
                position_id: bulkUploadPayload?.position?.original?.id,
                pre_screening_id: prescreenData?.id || null,
                test_validity: !canEdit ? testValidity?.value : null,
                start_timestamp: canEdit ? startTimeStamp : null,
                time_window: canEdit ? timeWindow : null,
                last_allowed_submission_timestamp: canEdit ? lastAllowedTimeStamp : null,
                send_test_without_approval: checked,
                skip_screening: false,
                token: token,
                env: reactAppStage,
            }

            console.log(payload);
            socket.current.send(JSON.stringify(payload));
            socket.current.onmessage = function ({ data }) {
                let res = JSON.parse(data);
                console.log(res);
                if (res) {
                    setLoader(false);
                    handleClose();
                }

                if (res?.progress) {
                    bulkStatus.status = true;
                    bulkStatus.data = res?.progress;
                    setBulkStatus({ ...bulkStatus });
                }

                if (res?.success_application_count || res?.success_interview_count || res?.success_prescreen_count || res?.failed_reason) {
                    bulkStatus.status = false;
                    bulkStatus.data = "";
                    setBulkStatus({ ...bulkStatus });
                    setBulkUploadData(res);
                }

            }

        } catch (error) {
            setLoader(false);
            cogoToast.error(
                error?.data.msg ||
                error?.data.data?.toString() ||
                error?.data?.toString() ||
                error?.toString() ||
                "File could not be uploaded",
                {
                    position: "top-right",
                }
            );
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box className="prescreen-bulk-modal-container">
                <Box className="main-container">
                    <Box className="title-box">
                        <Box width="100%" height="fit-content">
                            Upload Candidates in Bulk
                        </Box>
                        <Box height="fit-content">
                            <IconButton onClick={handleClose} aria-label="comment">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider />
                    <Box className="body-box">
                        <Box className="upload-content-box">
                            <Box className="content-box">
                                <Box className="body-head">
                                    Upload by candidate details (CSV & XLSX)
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    gap="16px"
                                    alignItems="center"
                                >
                                    {
                                        files?.name && files?.name?.length > 0 ?
                                            <Typography className="file-name">{files?.name}</Typography> : null
                                    }
                                    <Box className={`${!files?.name ? "display-block" : "display-none"}`}>
                                        <label for="file-upload-1">
                                            <Box className="upload-box">
                                                <Typography className="text-1">
                                                    Choose File
                                                </Typography>
                                                <EastIcon />
                                                <input
                                                    type="file"
                                                    className="input-styles"
                                                    id="file-upload-1"
                                                    onChange={(e) => handleFileUpload(e, "input1")}
                                                />
                                            </Box>
                                        </label>
                                    </Box>
                                </Box>
                                {
                                    files?.name?.length > 0 ?
                                        <Box display="flex" gap="20px">
                                            <label for="file-upload-1">
                                                <Typography className="blue-text">Choose New</Typography>
                                            </label>
                                            <Typography className="blue-text" onClick={handleRemoveFile}>Remove</Typography>
                                        </Box>
                                        : null
                                }
                                <Box>
                                    <Typography className="text-2">
                                        To prepare your candidats list in the right format.
                                    </Typography>
                                    <Typography className="text-3">
                                        Please download our{" "}
                                        <a href="https://docs.google.com/spreadsheets/d/1o4TBwoPVxwOPHqkhmaHiup7Ol8KBjuzSyYHJOLJH8R8/edit#gid=0" target="_blank">
                                            <span className="link-2">sample template</span>
                                        </a>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="preScreening-enabled-parent">
                        <Box className="preScreening-header"><ErrorOutlineIcon /><Typography className="heading b-400 size-13">This position is <span className="bold">Pre-screening</span> enabled.</Typography></Box>
                        <Box className="details-parent">
                            <Typography className="heading b-600 size-13">PRE-SCREENING TEST DETAILS</Typography>
                            <Box className="details-child">
                                <Typography className="heading color size-12">Questions asked: <span className="bold">{prescreenData?.number_of_questions}</span>&nbsp; &nbsp;</Typography>
                                <Typography className="heading color size-12">| &nbsp;&nbsp;  Cut-off: <span className="bold">{prescreenData?.cut_off_percentage}%</span>&nbsp;&nbsp;</Typography>
                                <Typography className="heading color size-12">|  &nbsp;&nbsp; Time Limit: <span className="bold">{prescreenData?.time_allotted} mins</span></Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box className="test-validity-container" >
                        <Box sx={{ display: "flex", flexDirection: "column", alignSelf: "baseline" }}>
                            <FormControlLabel control={<Radio checked={!canEdit} size="small" />} label={<Box className="formlabel-text">Test Link Validity</Box>} onChange={() => handleRadioOptionChange(false)} />
                            <Select
                                name="prescreen-test-validity"
                                placeholder="Select days"
                                className="select-dropdown"
                                isDisabled={canEdit}
                                options={testValidityOptions}
                                value={testValidity}
                                onChange={handleTestValidityChange}
                            />
                        </Box>
                        <Box marginTop="5px">
                            or
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", alignSelf: "baseline" }}>
                            <FormControlLabel control={<Radio checked={canEdit} size="small" />} label={<Box className="formlabel-text">Set the test start timestamp</Box>} onChange={() => handleRadioOptionChange(true)} />
                            <Typography className="label-text ml-6">Please mention the start time for the test.</Typography>

                            <DatePicker
                                id="datetime"
                                className="datepicker-style"
                                placeholderText="Select start timestamp"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                timeFormat="h:mm a"
                                autoComplete="no"
                                showTimeSelect
                                minDate={moment().toDate()}
                                timeIntervals={30}
                                timeCaption="Time"
                                disabled={!canEdit}
                                onChange={handleStartTimeStamp}
                                selected={startTimeStamp * 1000}
                            />

                            <Typography className="label-text ml-6">Please mention time window for the test in minutes.</Typography>

                            <TextField
                                className="test-name-input"
                                size="small"
                                variant="outlined"
                                id="outlined-secondary"
                                name="timeWindow"
                                label
                                disabled={!canEdit}
                                value={timeWindow}
                                onChange={handlePreScreenTimeWindow}
                            />

                            <Typography className="label-text ml-6">Please mention last allowed submission time for the test.</Typography>

                            <DatePicker
                                id="datetime"
                                className="datepicker-style"
                                placeholderText="Select last allowed submission timestamp"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                timeFormat="h:mm a"
                                autoComplete="no"
                                showTimeSelect
                                minDate={moment().toDate()}
                                timeIntervals={30}
                                timeCaption="Time"
                                disabled={!canEdit}
                                onChange={handleLastSubmissionTimestamp}
                                selected={lastAllowedTimeStamp * 1000}
                            />

                        </Box>
                    </Box>

                    <Box className="checkbox-style">
                        <Checkbox
                            color="primary"
                            checked={checked}
                            onChange={(e) => handleCheckBox(e.target.checked)}
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />
                        <Typography className="text-4">Send test right away without approval</Typography>
                    </Box>

                    <Box display="flex" justifyContent="flex-end" marginTop="10px">
                        <Box display="flex" marginRight="10px" alignItems="center">
                            <Button className="proceed-btn" onClick={handleSubmit}>
                                Upload
                            </Button>
                            {loader ? <CircularProgress size="20px" /> : ""}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default PrescreenBulkModal;
