import { Modal } from "@iv/shared/components/atoms/Modal/Modal.jsx";
import Box from "@mui/material/Box";
import yellowIcon from "../Images/Vector3.svg"
import "./UnsavedModal.scss";

const UnsavedModal = ({ showModal, setShowModal,handleStay, handleYes,...props }) => {

    const Body = () => {
        return (
            <Box className="unsaved-modal">
                <Box className="modal-box">
                    <Box className="modal-head">
                        <img src={yellowIcon} width="20px" alt="icon" />
                        <h1 className="head-text">Unsaved details</h1>
                    </Box>
                    <hr />
                    <Box className="modal-text">
                        <p>You have unsaved details which will be lost. Are you sure you want to <br />
                            cancel this drive?</p>
                    </Box>
                    <Box className="modal-button">
                        <Box className="white-button" onClick={handleStay} >Stay</Box>
                        <Box className="blue-button" onClick={handleYes} >Yes</Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Modal
            open={showModal}
            body={<Body setShowModal={setShowModal} />}
            {...props}
        />
    )

};

export default UnsavedModal;