import React, { useState } from "react";
import { useSelector, useDispatch} from "react-redux";
import { Link,useHistory } from "react-router-dom";
import { SideBarArray } from "../../../config/SidebarConfig";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import "./Sidebar.scss";
import { logoutUser } from "../../../services/auth";
import { companyprofile } from "../../../store/actions/CompanyProfile";
import { clearAuthUser } from "../../../store/actions/User";
import { logoutSuccess } from "../../../store/actions/Auth";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Popup from 'reactjs-popup';
import quickImg from "../../../assets/images/quick-link.svg";
import handleClient from "../../../Utils/client";
import { DemoModal } from "./DemoModal";
import ImageLoad from "../../atoms/ImageLoad/ImageLoad";
import VideoIcon from "../../../assets/images/video-icon.svg";

const Sidebar = ({ index }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDemoOpen,setIsDemoOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const logoUrl = useSelector(state=>state.selected_client.account_info.logoUrl);
  const client = useSelector((state) => state.selected_client);

  const handleLogout = async () => {
    try {
      await logoutUser();
      dispatch(clearAuthUser({}));
      dispatch(logoutSuccess());
      dispatch(companyprofile(""));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Card className="sidebar-main-container">
      <Box display="flex" flexDirection="row" justifyContent='center' alignItems='center' className="top-container">
      <ImageLoad className="sidebar-logo" url={logoUrl} placeholder={"/images/empty-logo.svg"} />
      </Box>

    <Box display="flex" flexDirection="column" className="text-container">

        {SideBarArray?.map((item, itemIndex) => {
          return (
            ((handleClient(user?.roles)) || (!handleClient(user?.roles) && item?.name !== 'Integrations')) ?
            <Link
                key={itemIndex}
                to={item?.path}
                className={(index && index === itemIndex + 1) ? "background-selected" : "background-unselected"}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <img
                  className="side-bar-icon"
                  src={index === itemIndex + 1 ? item.iconHigh : item.iconLow} alt="img"
                />
                <Typography
                  className={
                    index === itemIndex + 1 ? "label-bold" : "label-light"
                  }
                >

                  {item.name}
                </Typography>
              </Box>
            </Link> : <></>
          );
        })}

       <span className="seperation-line"/>
      { handleClient(user?.roles) && <Box className="newChanges-box">
        <Typography className='quick-link-text'>Quick Links <img className='quick-link-img' src={quickImg} alt="link-icon"/> </Typography>
        { client?.config_?.is_pre_screening_enabled?.value && 
          <Link to="/quickscreen/list">
                <Typography className="quick-links">
                  QuickScreen Tests
                </Typography>
        </Link>}
        
        <Link to="/interview-guides">
                <Typography className="quick-links">
                  View Interview Guides
                </Typography>
        </Link>

        <Link to="/settings/headhunter/organization">
                <Typography className="quick-links">
                  Add User/Consultant
                </Typography>
        </Link>
            <Box onClick={()=>setIsDemoOpen(true)} className="video-link pointer">
              <span className="quick-links">Dashboard Walkthrough</span> &nbsp;
              <img src={VideoIcon} alt="play-icon" className="mb-9"/>
            </Box>
        </Box>}
        <DemoModal open={isDemoOpen} width="1100px" height="700px" onClose={()=>setIsDemoOpen(false)}/>
        <Popup trigger={
          <Box className="profile-shortcut">
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <ImageLoad className="profile-pic" url={user?.profile_picture} placeholder={"/images/profile-placeholder.svg"}/>
            </IconButton>
            <Typography className="name-text">{user?.name || "user"}</Typography>
          </Box>
        } position="top right">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            className='profile-menu'
            >
            <MenuItem className="list-common" onClick={()=>history.push("/settings/myaccount/configuration")}>
              My Profile
            </MenuItem>
            <div className="border-bottom mb-8 mt-8"></div>
            <MenuItem className="list-common" onClick={handleLogout}>
              Logout
            </MenuItem>
          </Box>
        </Popup>

      </Box>
    </Card>
  );
};

export default Sidebar;
