import React, { Suspense } from 'react';
import { Redirect, BrowserRouter as Router, Switch } from "react-router-dom";
import PublicRoute from "./PublicRoute";

import PrivateRoute from "./PrivateRoute";
import AuthRoute from "./AuthRoute";
import ClientChooserRoute from "./ClientChooserRoute";
import Loader from "../components/organisms/Loader";
import ProtectedRoute from "./ProtectedRoute";
import { verifyFeedbackAccess } from "../services/feedback";
import { verifyGuideAccess } from '../services/InterviewGuide';
import { verifyPrescreeningAccess } from '../services/prescreening';
import RouteTracker from "./RouteTracker";
import Retriggered from '../components/pages/Retriggered/Retriggered';
import InterviewCreated from "../components/pages/InterviewCreated/index"
import TokenError from '../components/pages/Retriggered/TokenError';
import { useSelector } from 'react-redux';
import PrescreeningPreview from '../components/pages/Prescreening/PrescreeningPreview';
import HiringDriveForm from '../components/pages/HiringDrive/DriveForm';
import { verifyReportAccess } from '../services/prescreening';
import TestCompletedScreen from '../components/pages/Prescreening/PrescreeningTest/TestCompletedScreen';

const AuthorizedLogin = React.lazy(() => import('../services/AuthorizedLogin'));
const ClientChooser = React.lazy(() => import('../components/pages/ClientChooser'));
const Dashboard = React.lazy(() => import('../components/pages/Dashboard/Dashboard'));
const LoginPage = React.lazy(() => import('../components/pages/LoginPage'));
const PositionList = React.lazy(() => import('../components/pages/PositionList/PositionList'));
const InterviewList = React.lazy(() => import('../components/pages/InterviewList'));
const ProfileNew = React.lazy(() => import('../components/pages/Profile/ProfileNew'));
const Account = React.lazy(() => import('../components/pages/Handle'));
const History = React.lazy(() => import('../components/pages/History'));
const Search = React.lazy(() => import('../components/pages/SearchCandidate'));
const InterviewSlots = React.lazy(() => import('../components/pages/InterviewSlots'));
const ThankYouPage = React.lazy(() => import('../components/pages/ThankYouPage'));
const Settings = React.lazy(() => import('../components/pages/Settings'));
const Pipeline = React.lazy(() => import('../components/pages/Pipeline'));
const ResetPassword = React.lazy(() => import('../components/pages/ResetPassword'));
const ForgotPassword = React.lazy(() => import('../components/pages/ForgotPassword'));
const AddPosition = React.lazy(() => import('../components/pages/AddNewPosition/AddPosition/AddPosition'));
const PositionBackground = React.lazy(() => import('../components/pages/AddNewPosition/PositionBackground'));
const PositionGuideLinks = React.lazy(() => import('../components/pages/AddNewPosition/PositionGuideLinks'));
const Feedback = React.lazy(() => import('../components/pages/Feedback/index'));
const Integration = React.lazy(() => import('../components/pages/IntegrationPage'));
const IntegrationJobs = React.lazy(() => import('../components/pages/IntegrationJobs'));
const Cancel = React.lazy(() => import('../components/pages/CancelInterview'));
const InterviewGuides = React.lazy(() => import("../components/pages/InterviewGuides/GuideForm"));
const GuideList = React.lazy(() => import('../components/pages/InterviewGuides/GuideList'));
const GuidePreview = React.lazy(() => import('../components/pages/InterviewGuides/GuidePreview'));
const RetryPageIntegration = React.lazy(() => import('../components/pages/RetryPageIntegration/RetryPageIntegration'));
const SlackIntegration = React.lazy(() => import('../components/pages/SlackIntegration'));
const WhatsappIntegration = React.lazy(() => import('../components/pages/WhatsappIntegration'));
const CompanyProfile = React.lazy(() => import('../components/pages/Settings/CompanyProfile'));
const PrescreeningLogin =React.lazy(() => import('../components/pages/Prescreening/PrescreeningLogin'));
const PrescreeningTest =React.lazy(() => import('../components/pages/Prescreening/PrescreeningTest'));
const PrescreeningReport =React.lazy(() => import('../components/pages/Prescreening/PrescreeningReport'));
const PrescreeningList =React.lazy(() => import('../components/pages/Prescreening/PrescreeningList'));
const PrescreeningForm =React.lazy(() => import('../components/pages/Prescreening/PrescreeningForm'));
const GoogleLogin = React.lazy(() => import('../components/pages/GoogleMSLogin/GoogleLogin'));
const MicrosoftLogin = React.lazy(() => import('../components/pages/GoogleMSLogin/MicrosoftLogin'));
const Application =React.lazy(() => import('../components/pages/ApplicationSystem'));
const HiringList = React.lazy(() => import('../components/pages/HiringDrive/HiringList'));
const HiringTracker =React.lazy(() => import('../components/pages/HiringDrive/HiringTracker'));
const AppRouter = ({ isAuthenticated, isClientSelected }) => {
    return (
        <Suspense fallback={<Loader/>}>
            <Router>
                <Switch>
                    <PublicRoute exact path="/authorize" component={AuthorizedLogin} />
                    <AuthRoute exact path="/login" component={LoginPage} isAuthenticated={isAuthenticated} />
                    <ClientChooserRoute
                        exact path="/client-chooser"
                        component={ClientChooser}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                    <PrivateRoute
                        exact path="/dashboard"
                        component={Dashboard}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                    <PrivateRoute
                        exact path="/positions/background"
                        component={PositionBackground}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                   <PrivateRoute
                        exact path="/positions/guide-links"
                        component={PositionGuideLinks}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                    <PrivateRoute
                        exact path="/interview-guides"
                        component={GuideList}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                     <PrivateRoute
                        exact path="/interview-guides/add"
                        component={InterviewGuides}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />

                    <PrivateRoute
                        exact path="/positions/new/:activePage?"
                        component={AddPosition}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                    <PrivateRoute
                        path="/positions/:id"
                        component={AddPosition}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />

                    <PrivateRoute
                        path="/interview/new/:activePage?"
                        redirect="/application"
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                        action="NEW"
                    />
                

                    <PrivateRoute
                        path="/prescreenTest/edit"
                        redirect="/application"
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                        action="PRESCREEN-EDIT"
                    />

                    <PrivateRoute
                        path="/prescreenTest/New"
                        redirect="/application"
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                        action="PRESCREEN-NEW"
                    /> 

                    <PrivateRoute
                        path="/prescreenTest/schedule"
                        redirect="/application"
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                        action="PRESCREEN-SCHEDULE"
                    />

                    <PrivateRoute
                        exact path="/interview/edit/:activePage?"
                        redirect="/application"
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                        action="EDIT"
                    />
                    <PrivateRoute
                        exact path="/interview/reschedule"
                        redirect="/application"
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                        action="RESCHEDULE"
                    />
                    <PrivateRoute
                        exact path="/interview/schedule"
                        redirect="/application"
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                        action="SCHEDULE"
                    />
                    <PrivateRoute
                        exact path="/interview/scheduleNext"
                        redirect="/application"
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                        action="scheduleNext"
                    />
                    <PrivateRoute
                        path="/interviews/:tab?"
                        component={InterviewList}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                    <PrivateRoute
                        exact path="/positions"
                        component={PositionList}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                    <PrivateRoute
                        exact path="/profile"
                        component={ProfileNew}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                    <PrivateRoute
                        exact path="/account"
                        component={Account}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoute
                        exact path="/position/edit/:id/:activePage?/:status?"
                        component={AddPosition}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                    <PrivateRoute
                        exact path="/candidate/search"
                        component={Search}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                    <PrivateRoute
                        exact path="/history"
                        component={History}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                    <PrivateRoute
                        exact path="/slack-integration"
                        component={SlackIntegration}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                    <PrivateRoute
                        exact path="/whatsapp-integration"
                        component={WhatsappIntegration}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                     <PrivateRoute
                        exact path="/company-profile"
                        component={CompanyProfile}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                    {/* <PrivateRoute
                        path='/setting/:tab?'
                        component={Setting}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    /> */}
                    <PrivateRoute
                        path='/settings/:tab?'
                        component={Settings}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                    <PrivateRoute
                        exact path="/pipeline/:position?"
                        component={Pipeline}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                     <PrivateRoute
                        exact path="/integration/:client?/:status?/:subStatus?"
                        component={Integration}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />

                     <PrivateRoute
                        exact path="/integration-jobs"
                        component={IntegrationJobs}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                    <PrivateRoute
                        exact path="/application"
                        component={Application}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                    <PrivateRoute
                        exact path="/hiring-list"
                        component={HiringList}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />
                    <PrivateRoute
                        exact path="/hiring-tracker"
                        component={HiringTracker}
                        isAuthenticated={isAuthenticated}
                        isClientSelected={isClientSelected}
                    />

                    <PublicRoute path="/reset" component={ResetPassword} />
                    <PublicRoute path="/forgot-password" component={ForgotPassword} />
                    <PublicRoute path="/google/user/:token?" component={GoogleLogin} />
                    <PublicRoute path="/microsoft/user/:token?" component={MicrosoftLogin} />
                    <PublicRoute path='/interviewSlots' component={InterviewSlots} action="SLOTS"/>
                    <PublicRoute path='/thankYou' component={ThankYouPage} />
                    <PublicRoute path='/interview-feedback' component={Feedback} />
                    <PublicRoute path='/cancel-interview' component={Cancel} />
                    <PublicRoute path='/reschedule-interview' component={InterviewSlots} action="RESCHEDULE" />
                    <PublicRoute path="/retry-lever-opportunity/:token?" component={Retriggered} />
                    <PublicRoute path="/retry-stage-change/:token?/:integration?" component={RetryPageIntegration} />
                    <PublicRoute path="/next-round-interview/:token?" component={InterviewCreated} />
                    <PublicRoute path="/greenhouse/next-round-interview/:token?" component={InterviewCreated} />
                    <PublicRoute path="/workable/next-round-interview/:token?" component={InterviewCreated} />
                    <PublicRoute path="/create-round-interview/:token?" component={TokenError} />
                    <PublicRoute path="/interview-guides/preview" component={GuidePreview} />

                    <PublicRoute path="/candidate_pre_screening_round/:token?" redirect="/candidate_quick_screen_round"/>
                    <PublicRoute path="/candidate_quick_screen_round/:token?" component={PrescreeningLogin} />

                    <PublicRoute path="/prescreening/test" redirect="/quickscreen/test"/>
                    <PublicRoute path="/quickscreen/test" component={PrescreeningTest}/>

                    <PublicRoute path="/prescreening/list" redirect="/quickscreen/list"/>
                    <PublicRoute path="/quickscreen/list" component={PrescreeningList}/>

                    <PublicRoute path="/prescreening/add" redirect="/quickscreen/add"/>
                    <PublicRoute path="/quickscreen/add" component={PrescreeningForm}/>

                    <PublicRoute path="/prescreening/preview" redirect="/quickscreen/preview"/>
                    <PublicRoute path="/quickscreen/preview" component={PrescreeningPreview}/>                    

                    <PublicRoute path="/hiring-drive/form" component={HiringDriveForm}/>                    

                    <PublicRoute 
                        path={`/prescreen/report/:client_id?/:position_id?/:candidate_id?/:prescreen_id?/:prescreen_round_id?`} 
                        redirect="/quickscreen-report"
                    />
                    <PublicRoute 
                        path={`/quickscreen-report`} 
                        component={PrescreeningReport} 
                    />
                    
                    <PublicRoute path="/quickscreen/feedback" component={TestCompletedScreen}/>

                    <ProtectedRoute
                        path="/feedback"
                        redirect="/interview-feedback?i_id=${}"
                        params={["interview_id"]}
                        authenticator={verifyFeedbackAccess}
                        isAuthenticated={isAuthenticated}
                    />
                    <ProtectedRoute
                        path="/guides"
                        redirect="/interview-guides/preview?guide_id=${}&position_id=${}"
                        params={["guide_id","position_id"]}
                        authenticator={verifyGuideAccess}
                        isAuthenticated={isAuthenticated}
                    />
                    <ProtectedRoute
                        path="/screening/report"
                        redirect="/prescreen/report/?client_id=${}&prescreen_round_id=${}"
                        params={["client_id","candidate_screening_id"]}
                        authenticator={verifyReportAccess}
                        isAuthenticated={isAuthenticated}
                    /> 
                    <ProtectedRoute
                        path="/pre_screening"
                        redirect="/prescreening/preview?id=${}"
                        params={["id"]}
                        authenticator={verifyPrescreeningAccess}
                        isAuthenticated={isAuthenticated}
                    />
                    <Redirect from="*" to="/dashboard" />
                </Switch>
                <RouteTracker/>
            </Router>
        </Suspense>
    );
};

export default AppRouter;
