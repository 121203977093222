import { Modal } from "@iv/shared/components/atoms/Modal/Modal.jsx";
import Box from "@mui/material/Box";
import "./DeleteCandidateModal.scss";

const DeleteCandidateModal = ({ showModal, setShowModal,handleCancel, handleDelete,...props }) => {

    const Body = () => {
        return (
            <Box className="delete-modal">
                <Box className="modal-box">
                    <Box className="modal-head">
                        <h1 className="head-text">Delete Candidate</h1>
                    </Box>
                    <hr />
                    <Box className="modal-text">
                        <p>Are you sure you want to delete the candidate?</p>
                    </Box>
                    <Box className="modal-button">
                        <Box className="white-button" onClick={handleCancel} >Cancel</Box>
                        <Box className="blue-button" onClick={handleDelete} >Delete Candidate</Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Modal
            open={showModal}
            body={<Body setShowModal={setShowModal} />}
            {...props}
        />
    )

};

export default DeleteCandidateModal;