import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FORM_TYPE, STEPPER_STEPS, STEPPER_STEPS_KEY } from "../constant";
import "./stepper.scss";

const Stepper = ({
    currentStep,
    setCurrentStep,
    formType,
    setUrlQueryParam,
    isFormDetailsValid,
    handleNextClick,
}) => {
    const stepperSteps = STEPPER_STEPS;
    const lastIndex = STEPPER_STEPS?.length - 1;
    const getClass = (index, isDisabled) => {
        let temp = "step ";
        if (index === 0) {
            temp += "first-step ";
        }
        if (index === lastIndex) {
            temp += "last-step ";
        }
        if (currentStep?.index === index) {
            temp += "current ";
        }
        else if (index < currentStep?.index) {
            temp += "complete ";
        }
        if (isDisabled) {
            temp += "disable ";
        }
        return temp;
    }

    const handleStepChange = (index, key, route, isDisabled) => {
        if (!isDisabled) {
            if ((key === STEPPER_STEPS_KEY.ADD_RECRUITERS) && !isFormDetailsValid()) {
                return;
            } else  if ((key === STEPPER_STEPS_KEY.ADD_CANDIDATES) && !isFormDetailsValid()) {
                return;
            } else if (key === STEPPER_STEPS_KEY.ADD_CANDIDATES) {
                    handleNextClick();
            } else {
                setCurrentStep({
                    key: key,
                    index: index,
                });
            }
            setUrlQueryParam('currentTab', route);
            // setUrlQueryParam('formType', formType);
        }
    }

    return <Box className="hiring-drive-stepper">
        {
            stepperSteps?.map(({ icon, label, route, key, isDisabled }, index) => {
                return (
                    <Box className={getClass(index, isDisabled)} onClick={() => handleStepChange(index, key, route, isDisabled)}>
                        <Box>
                            <img src={
                                isDisabled ? icon.unvisited :
                                    currentStep?.index == index ?
                                        icon?.active :
                                        currentStep?.index > index ?
                                            icon.visited :
                                            icon?.unvisited
                            } alt={label} />
                        </Box>
                        <Typography className="text">
                            {label}
                        </Typography>
                    </Box>
                )
            })
        }
    </Box>
}
export default Stepper;