import axios from "./axios";

export const getClientUsers = (clientId) => {
    return axios.get(`/client/${clientId}/users`)
      .then((response) => response.data);
  };

export const addClientUsers = (payload) => {
  return axios.post(`/client_user/create`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const editClientUsers = (payload) => {
  return axios.post(`/client_user/edit`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const updateClientUserStatus = (payload) => {
  return axios.post(`/set-active-status`,payload)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getClientConfigAPI = (client_id)=>{
  return axios.get(`/client/${client_id}/config`).then(response=>{
    return response;
  }).catch(error=>{
    throw error?.response;
  })
}

export const saveConfigAPI = (client_id,payload)=>{
  return axios.post(`/client/${client_id}/config`,payload).then(response=>{
    return response;
  }).catch(error=>{
      throw error?.response;
  });
}

export const getAllInterviewer = (payload) => {
  return axios.post(`/interviewer/all`, payload).then((response) => {
      return response;
  }).catch(error=>{
      throw error?.response;
  });
};

export const add_interviewer = (interviewerPayload) => {
  return axios
      .post("/interviewer/register", interviewerPayload)
      .then(response => {
          return response;
      }).catch(err => {
          return err.response;
      })
};

export const edit_interviewer = (id, interviewerPayload) => {
  return axios
      .post(`/interviewer/${id}`, interviewerPayload)
      .then(response => {
          return response;
      }).catch(err => {
          return err.response;
      })
};

export const getAllRolesRoundCategoriesResources = () => {
  return axios.get('/role_round_category_resources/all')
      .then((response) => {
          return response.data;
      })
      .catch(error => {
          throw error?.response;
      })
};

export const getAllRolesRoundCategoriesDictV2 = () => {
  return axios.get('/role_round_category_resources/v2/dict')
      .then((response) => {
          return response.data;
      })
      .catch(err => {
          return err.response;
      })
};