import ModalMUI from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./DemoModal.scss";
import { useEffect, useState } from "react";
import { downloadVideo } from "../../../services/file";
import VideoIcon from "../../../assets/images/video-icon.svg";
import DashboardThumbnail from "../../../assets/images/dashboard-thumbnail.png";
import InternalInterviewThumbnail from "../../../assets/images/Internal-interview-thumbnail.png";
import QuickScreenThumbnail from "../../../assets/images/quickscreen-thumbnail.png";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import cogoToast from "cogo-toast";
import Typography from "@mui/material/Typography";
const CSM_ONBOARDING_KEY = "csm_onboarding_video.mp4";
const INTERNAL_INTERVIEW_KEY = "internal_interview.mp4";
const QUICKSCREEN_KEY = "quickscreen_demo.mp4";
export const DemoModal = ({ ...props }) => {
  const [videoSrc, setVideSrc] = useState()
  const [videoIndex, setVideoIndex] = useState(1);

  const handleVideo = () => {
    let bucket_name = "";
    let s3_key = "";
    if (process.env.NODE_ENV === "production" && process.env.REACT_APP_STAGE === "prod")
      bucket_name = "prod-interview-recordings";
    else
      bucket_name = "ivector";
    if (videoIndex === 1) {
      s3_key = CSM_ONBOARDING_KEY
    } else if (videoIndex === 2) {
      s3_key = INTERNAL_INTERVIEW_KEY
    } else {
      s3_key = QUICKSCREEN_KEY
    }
    let url = `bucket_name=${bucket_name}&s3_key=${s3_key}`;
    downloadVideo(url).then(res => {
      if (res?.status === 200) {
        setVideSrc(res?.data);
      }
      else {
        cogoToast?.error(res?.data?.data?.toString() || res?.data?.msg || res?.data?.toString() || res?.toString(), {
          position: "top-right"
        });
      }
    }).catch(err => {
      cogoToast?.error(err?.data?.msg || err?.data?.toString() || err?.toString(), {
        position: "top-right"
      });
    })
  }
  useEffect(() => {
    if (videoSrc) {
      setVideSrc();
    }
    handleVideo();
  }, [videoIndex])

  const handlePreviousVideo = () => {
    if (videoIndex > 1) {
      setVideoIndex(videoIndex - 1);
    }
  }

  const handleNextVideo = () => {
    if (videoIndex < 3) {
      setVideoIndex(videoIndex + 1);
    }
  };

  return (
    <ModalMUI {...props}>
      <Box className="client-demo-modal">
        <Box
          height={props?.height}
          width={props?.width}
          onClick={(e) => e.stopPropagation()}
          className="main-container"
        >
          <>
            <Box className="title-box">
              <Box height="fit-content"><img src={VideoIcon} alt="play-icon" />&nbsp;&nbsp;Dashboard Walkthrough - Videos</Box>
              <Box height="fit-content">
                <IconButton onClick={props?.onClose} aria-label="comment">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Divider />
          </>
          <Box className="body-box">
            <Box className="left-container">
              <Box className="thumbnail-box" onClick={() => setVideoIndex(1)}>
                1. &nbsp;<Box>
                  <img src={DashboardThumbnail} className={`thumbnail-style ${videoIndex === 1 ? "blue-border" : "black-border"}`} alt="dashboard video" />
                  <Box className={`thumbnail-title ${videoIndex === 1 ? "blue-bg" : "black-bg"}`}>Dashboard</Box>
                </Box>
              </Box>
              <Box className="thumbnail-box" onClick={() => setVideoIndex(2)}>
                2. <Box>
                  <img src={InternalInterviewThumbnail} className={`thumbnail-style ${videoIndex === 2 ? "blue-border" : "black-border"}`} alt="internal interview video" />
                  <Box className={`thumbnail-title ${videoIndex === 2 ? "blue-bg" : "black-bg"}`}>Internal Interview</Box>
                </Box>
              </Box>
              <Box className="thumbnail-box" onClick={() => setVideoIndex(3)}>
                3. <Box>
                  <img src={QuickScreenThumbnail} className={`thumbnail-style ${videoIndex === 3 ? "blue-border" : "black-border"}`} alt="quickscreen video" />
                  <Box className={`thumbnail-title ${videoIndex === 3 ? "blue-bg" : "black-bg"}`}>QuickScreen</Box>
                </Box>
              </Box>
            </Box>
            <Box className="right-container">
              <Typography className="video-title">
                {videoIndex === 1 ? "1. Dashboard Demo"
                  : videoIndex === 2 ? "2. Internal Interview Demo"
                    : "3. QuickScreen Demo"}
              </Typography>
              {
                videoSrc ?
                  <iframe src={videoSrc} width="780" height="500" frameBorder="0" title="video url" allowFullScreen />
                  :
                  <Typography className="loader-style">Loading...</Typography>
              }
              <Box className="button-box">
                <Box className={`btn-style ${videoIndex === 1 ? "inactive-btn" : ""}`} onClick={handlePreviousVideo}><ArrowBackIosNewRoundedIcon className="arrow-size"/></Box>  
                <Box className={`btn-style ${videoIndex === 3 ? "inactive-btn" : ""}`} onClick={handleNextVideo}><ArrowForwardIosRoundedIcon className="arrow-size"/></Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ModalMUI>
  )
}