import React, { useState, useEffect } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { loadImage } from "../../../Utils/image";
import "react-lazy-load-image-component/src/effects/blur.css";

const ImageLoad = ({ url,className, placeholder }) => {
  const [image, setImage] = useState("");
  
  useEffect(() => {
    if (url) {
      loadingImage(url);
    }
  }, [url]);

  const loadingImage = (url) => {
    loadImage(url, (err, result) => {
      if (result) {
        setImage(result);
      }
    });
  };
  return (
    <LazyLoadImage
      height="100%"
      width="100%"
      effect="blur"
      src={image ? image : placeholder}
      className={className}
    />
  );
};

export default ImageLoad;
