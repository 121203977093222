export const STEPPER_STEPS_KEY = {
    DRIVE_DETAILS : "drive-details",
    ADD_RECRUITERS : "add-recruiters",
    ADD_CANDIDATES : "add-candidates",
}

export const STEPPER_STEPS = [
    {
        icon:{
            active:"/images/hiring-drive/drive-details-active.svg",
            visited:"/images/hiring-drive/drive-details-inactive.svg",
            unvisited:"/images/hiring-drive/drive-details-inactive.svg",
        },
        label:"Drive Details",
        route:"drive-details",
        key:STEPPER_STEPS_KEY.DRIVE_DETAILS,
        isDisabled:false,
    },
    {
        icon:{
            active:"/images/hiring-drive/add-recruiters-active.svg",
            visited:"/images/hiring-drive/add-recruiters-visited.svg",
            unvisited:"/images/hiring-drive/add-recruiters-inactive.svg",
        },
        label:"Add Recruiters",
        route:"add-recruiters",
        key:STEPPER_STEPS_KEY.ADD_RECRUITERS,
        isDisabled:false,
    },
    {
        icon:{
            active:"/images/hiring-drive/add-recruiters-active.svg",
            visited:"/images/hiring-drive/add-candidates-inactive.svg",
            unvisited:"/images/hiring-drive/add-candidates-inactive.svg",
        },
        label:"Add Candidates",
        route:"add-candidates",
        key:STEPPER_STEPS_KEY.ADD_CANDIDATES,
        isDisabled:false,
    },
]

export const FORM_TYPE = {
    ADD:"ADD",
    VIEW : "VIEW",
    EDIT : "EDIT",
}


export const ERRORS = {
    FILE_UPLOAD : "File couldn't be uploaded due to internal server error",
    RESUME_PARSE: "Resume Parsed Error!",
    FILE_DOWNLOAD: "File couldn't be downloaded due to internal server error",
}

export const SUCCESS = {
    RESUME_PARSE: "Resume Parsed Successfully, Please check the filled details before proceeding",
 }

export const RESUME_STATE = {
    INITIAL :"INITIAL",
    UPLOADING : "UPLOADING",
    UPLOADED : "UPLOADED",
}

export const PREFILLED_INITIAL = {
    status:null,
    message:"",
}

export const PREFILLED_SUCCESS = {
    status:true,
    message:"Auto-filled",
}

export const PREFILLED_ERROR = {
    status:false,
    message:"Couldn’t fetch last name",
}

export const INTERVIEW_CASES = {
    ACTIVE_INTERVIEW_FOR_SAME_POSITION: 'active_interviews_for_same_position',
    ACTIVE_INTERVIEW_FOR_SAME_ROUND: 'active_interviews_for_same_round',
    COOLING_PERIOD:'cooling_period',
}

export const ROUND_TYPE = {
    PARALLEL_ROUNDS: 'parallel_rounds',
}

/* Internal interview*/
export const INTERVIEWER_TYPE = {
    IV_EXPERTS:"INTERVIEWVECTOR",
    CLIENT_INTERNAL:"CLIENT_INTERNAL",
}

export const CLIENT_SUBSCRIBE = {
    IAAS:"iaas",
    SAAS:"saas",
    IAAS_SAAS:"iaasAndSaas",
}