import { useState } from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CandidateFormModal from '../../Popups/CandidateFormModal/CandidateForm'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import cogoToast from "cogo-toast";

import UnsavedModal from "../../Popups/Unsaved/UnsavedModal";
import DeleteCandidateModal from "../../Popups/DeleteCandidate/DeleteCandidateModal";
import { useHistory } from "react-router";
import { FORM_TYPE } from "../../constant";
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import './style.scss';
import EastIcon from "@mui/icons-material/East";
import CircularProgress from "@mui/material/CircularProgress";

const AddCandidates = ({ savedDriveData, candidateForm, setCandidateForm, resetCandidateForm, candidateResume, setCandidateResume, resetCandidateResume, candidateList, setCandidateList, clientId, positionId, addCandidatesToHiringDrive, bulkUploadFile, setBulkUploadFile, formType , isLoading}) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDeleteModal, setShowDeletelModal] = useState({status: false, fn: null});

  const history = useHistory();

  const [candidateFormModal, setCandidateFormModal] = useState({
    isOpen: false,
    isEdit: false,
    candidateData: null,
    index: -1
  });

  const isValidFileUploaded = (file) => {
    const validExtensions = ['csv', 'xlsx', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet']
    const fileExtension = file?.type?.split('/')[1]
    return validExtensions.includes(fileExtension)
  }
  const handleFileUpload = (e) => {
    // Handle the file upload logic here

    const file = e?.target?.files[0];
    if (!isValidFileUploaded(file)) {
      cogoToast.error("Please upload a valid file", { position: 'top-right' });
    } else if (file !== undefined) {
      setBulkUploadFile(e.target.files[0])
    }
    e.target.value = "";

  };

  const openCandidateForm = (isEdit, candidateData = null, index = -1) => {
    if(!isEdit && candidateList?.length >=4){
      cogoToast.error('Can not add more than 4 candidates')
      return;
    }
    setCandidateFormModal({ ...candidateFormModal, isOpen: true, isEdit: isEdit, candidateData: candidateData, index: index })
  }

  const handleRemoveFile = () => {
    setBulkUploadFile(null);
  }

  const renderBulkUpload = () => {
    return (<div className={candidateList?.length > 0 ? 'fade-box' : ''}>
      <Typography className='heading'>Upload in Bulk</Typography>
      <Box className="content-box" sx={{ mt: 1, mb: 1 }} >
        <Box
          display="flex"
          gap="10px"
          alignItems="center"
        >

          <Box className={`${!bulkUploadFile?.name ? "display-block" : "display-none"}`}>
            <label for="file-upload-1">
              <Box className="upload-box">
                <Typography className="text-1" >
                  {bulkUploadFile?.name && bulkUploadFile?.name?.length > 0 ? 'Choose Another' : 'Choose File'}
                </Typography>
                <EastIcon />
                <input
                  disabled={candidateList?.length > 0}
                  type="file"
                  className="input-styles"
                  id="file-upload-1"
                  onChange={(e) => handleFileUpload(e, "input1")}
                  accept=".csv, .xlsx"
                />
              </Box>
            </label>
          </Box>
          <Typography variant="caption" display="block" sx={{ mt: 1 }}>
            Upload by candidate details (CSV & XLSX)
          </Typography>

        </Box>
        {
          bulkUploadFile?.name && bulkUploadFile?.name?.length > 0 ?
            <Box display="flex" flexDirection={'row'} marginBottom='8px'>
              <Typography className="file-name" sx={{ mr: 1 }}>{bulkUploadFile?.name}</Typography>
              <Typography className="remove-text" onClick={handleRemoveFile}>Remove</Typography>
            </Box>
            : null
        }
        <Box>

          <Typography className="text-2">
            To prepare your candidates list in the right format.
          </Typography>
          <Typography className="text-3">
            Please download our{" "}
            <a href="https://docs.google.com/spreadsheets/d/1I5YkOTE4wkd3VGK37UWP3_7w9hLufhBoznQn6QB4m-k/edit?usp=sharing" target="_blank">
              <span className="link-2">sample template</span>
            </a>
          </Typography>
        </Box>
      </Box>
    </div>)
  }

  const renderAddCandidate = () => {
    return <div className={bulkUploadFile?.name && bulkUploadFile?.name?.length > 0 ? 'fade-box' : ''}>
      <Typography className='heading'>Add Candidate(s) Individually</Typography>

      <Button className="upload-box" sx={{ mt: 1, mb: 1 }} onClick={() => openCandidateForm(false)} disabled={bulkUploadFile?.name && bulkUploadFile?.name?.length > 0}>
        Add Candidate
      </Button>

      <Typography className='sub-text'>
        (Max. limit: 4) or Use Bulk upload for faster entry
      </Typography>

      {renderCandidateList()}
    </div>
  }

  const renderCandidateList = () => {
    const handleDelete = (index) => {
      const candidates = [...candidateList];
      candidates.splice(index, 1);
      setCandidateList(candidates);
    };

    const handleEdit = (index) => {
      // Add logic to handle edit action
      openCandidateForm(true, candidateList[index], index)
    };

    return (
      <Box sx={{ mt: 3 }}>

        {candidateList?.length > 0 && <Typography className='sub-text'>
          Added Candidate
        </Typography>}
        <Grid container spacing={1} sx={{ mt: 0.5 }}>
          {candidateList?.map((candidate, index) => (

            <Grid item xs={12} sm={6} key={index}>
              <Box
                key={index}
                display='flex'
                flexDirection='column'
                sx={{
                  padding: '10px', borderRadius: '8px', border: '1px solid #dcd8d8'
                }}
              >

                <Box display={'flex'} flexDirection='row' justifyContent='space-between' sx={{ marginBottom: '5px' }}>
                  <Typography className='name'>{candidate.firstName?.value}</Typography>

                  <div>
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(index)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => setShowDeletelModal({status: true, fn: () => handleDelete(index) })}
                    >
                      <DeleteIcon style={{ color: '#BF2929' }} />
                    </IconButton>
                  </div>
                </Box>

                <Typography className='name'>{candidate.emailId?.value}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );

  }

  const renderPositionDetails = () => {
    const roundsList = []

    savedDriveData?.position_data?.screening_details?.map((data, index) => {

      const obj = {
        label: `Quickscreen ${index + 1}`,
        name: data?.title
      }
      roundsList.push(obj)
    })


    savedDriveData?.position_data?.round_details?.map((data, index) => {

      const obj = {
        label: `Round ${index + 1}`,
        name: data?.name
      }
      roundsList.push(obj)
    })

    return <Box sx={{ mb: 3 }} className='position-details'>
      <Typography>
        <span className='label-heading'>Position:</span> <span className='label-value'>{savedDriveData?.position_data?.position_name}</span>
      </Typography>

      <Box display='flex' flexDirection='row' spacing={2} sx={{ mt: 1, overflowY: 'auto' }}>
        {
          roundsList?.map((data, index) => {
            return (
              <Box display={'flex'} flexDirection='row'>
                <div>
                  <Typography className='round-label'>{data?.label}:</Typography>
                  <Typography className='round-name'>{data?.name}</Typography>
                </div>
                {roundsList?.length > index + 1 && <div>
                  <EastIcon className="icon" sx={{ color: '#1844ad', width: '120px', height: '30px' }} />
                </div>}
              </Box>
            )
          })
        }
      </Box>
    </Box>

  }

  return (
    <Box className='add-candidates-form-container'>
      <Paper elevation={3} sx={{ p: 4, mt: 4, backgroundColor: 'white', borderRadius: '14px' }}>
        <Typography className='heading'>
          Add Candidates
        </Typography>
        <hr />
        <Box display={'flex'} flexDirection='column'>

          {renderPositionDetails()}

          <Grid container spacing={4} className='add-candidates-container'>
            <Grid item xs={12} md={5}>
              {renderBulkUpload()}
            </Grid>
            <Grid item xs={12} md={1}>
              <Divider sx={{ height: '100%', width: '1px', backgroundColor: '#dcdcdc' }} />
            </Grid>
            <Grid item xs={12} md={6}>
              {renderAddCandidate()}
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 3,
        }}
      >
        <Box>
          <Button onClick={() => setShowCancelModal(true)} className="close-btn">
            Cancel
          </Button>
        </Box>

        <Button onClick={addCandidatesToHiringDrive} className="send-btn" disabled={isLoading}>
          <Typography className="text">{candidateList?.length === 0 && !bulkUploadFile?.name  ? 'Add Candidates Later' : 'Finish'}</Typography>

          <Box height="20px">
            {
              isLoading ?
                <CircularProgress size="20px" className="loading-icon" />
                : candidateList?.length === 0 && !bulkUploadFile?.name ? <Box>
                  <ArrowRightAltOutlinedIcon className="icon" />
                </Box> : null}

          </Box>

        </Button>
      </Box>

      <UnsavedModal
        showModal={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        handleStay={() => setShowCancelModal(false)}
        handleYes={() => history.push(`/hiring-tracker?hiring_id=${savedDriveData?.drive_data?.id}`)}
      />

      <DeleteCandidateModal
        showModal={showDeleteModal?.status}
        onClose={() => setShowDeletelModal({ status: false, fn: null })}
        handleCancel={() => setShowDeletelModal({ status: false, fn: null })}
        handleDelete={() => {
          showDeleteModal.fn();
          setShowDeletelModal({ status: false, fn: null });
        }}
      />


      {candidateFormModal.isOpen && <CandidateFormModal
        candidateForm={candidateFormModal.isEdit && candidateFormModal.candidateData ? candidateFormModal.candidateData : candidateForm}
        setCandidateForm={setCandidateForm}
        resetCandidateForm={resetCandidateForm}
        candidateResume={candidateResume}
        setCandidateResume={setCandidateResume}
        resetCandidateResume={resetCandidateResume}
        candidateList={candidateList}
        setCandidateList={setCandidateList}
        candidateFormModal={candidateFormModal}
        setCandidateFormModal={setCandidateFormModal}
      />}

    </Box>
  );
};

export default AddCandidates;
