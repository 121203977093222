import { useState } from "react";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import './TrackerGuide.scss';

import slide1 from "./images/slide1.svg";
import slide2 from "./images/slide2.svg";
import slide3 from "./images/slide3.svg";
import slide4 from "./images/slide4.svg";
import slide5 from "./images/slide5.svg";
import slide6 from "./images/slide6.svg";
import previousArrow from "./images/previous-arrow.svg";
import nextArrow from "./images/next-arrow.svg";
import thumb from "./images/thumb-icon.svg";

const TrackerGuide = ({ setShowTrackerGuide }) => {
    const [index, setIndex] = useState(1);

    const handlePreviousSlide = () => {
        if (index > 1) {
            setIndex(index - 1);
        }
    }

    const handleNextSlide = () => {
        if (index < 6) {
            setIndex(index + 1);
        } else {
            setShowTrackerGuide(false);
        }
    };

    const slideTextImg = () => {
        return (
            <Box className="slide-main-box">
                {
                    (index === 1) ? (
                        <Box className="data-box">
                            <Box className="first-text">Now drive your hiring pipeline though clear actions.</Box>
                            <Box margin="15px">The new candidate tracker is divided into <b>3 parts</b></Box>
                            <img src={slide1} alt="slide" />
                        </Box>
                    ) : index === 2 ? (
                        <Box className="data-box">
                            <Box margin="25px">Manage all your applications in one place with clear actions, and simple & intuitive pipeline.</Box>
                            <img src={slide2} alt="slide" />
                        </Box>
                    ) : index === 3 ? (
                        <Box className="data-box">
                            <Box margin="25px">Save time & improve your hiring pipeline quality by screening candidates via <b>QuickScreen</b>.</Box>
                            <img src={slide3} alt="slide" />
                        </Box>
                    ) : index === 4 ? (
                        <Box className="data-box">
                            <Box margin="22px">With <b>IV expert interviewers</b> avoid bad hires, while saving your engineering bandwidth and providing best interview experience to your candidates.</Box>
                            <img src={slide4} alt="slide" />
                        </Box>
                    ) : index === 5 ? (
                        <Box className="data-box">
                            <Box margin="22px">Send the <b>Rejection Email</b> to candidates who could not clear interviews or failed at screening. Also, you can checkout internally rejected or dropped candidates here.</Box>
                            <img src={slide5} alt="slide" />
                        </Box>
                    ) : (
                        <Box className="data-box">
                            <Box margin="20px"><b>And something more...</b></Box>
                            <img src={slide6} alt="slide" />
                        </Box>
                    )
                }
            </Box>
        )
    }

    function previousButton() {
        if (index === 1) {
            return "inactive-button"
        }
        return "active-button"
    }

    return (
        <Box className="tracker-guide">
        <Box className="container">
            <Box className="main-box">
                <Box className="slide-header">
                    <span className="text-new">New</span><span className="text-candidate">Candidates</span><span className="text-tracker">Tracker</span>
                    <CloseIcon className="clear-icon" onClick={() => setShowTrackerGuide(false)} />
                </Box>
                <hr margin="0" />
                <Box>{slideTextImg()}</Box>
                <Box className="slide-buttons">
                    <Box className={previousButton()} onClick={handlePreviousSlide}><img src={previousArrow} alt="arrow" /> Previous</Box>
                    {(index === 6) ? <Box className="next-button" onClick={handleNextSlide}>Got it<Box marginLeft="5px"><img src={thumb} alt="thumb-icon" /></Box></Box>
                        : <Box className="next-button" onClick={handleNextSlide}>Next<img src={nextArrow} className="next-arrow" alt="arrow" /></Box>
                    }
                </Box>
            </Box>
        </Box>
        </Box>
    )
};

export default TrackerGuide;