import React, { useState, useEffect, useCallback, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { loadImage } from "../../../Utils/image";
import ClientSelect from "../ClientSelect";
import UserRoles from "../../../constants/UserRoles";
import Typography from "@material-ui/core/Typography";
import "./HeaderNav.scss";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from "@material-ui/core/IconButton";
// Action
import { updateProfileImage } from "../../../store/actions/updateProfilePic";

import NewIcon from "./images/new-icon.svg";
import addPositionButtonIcon from "./images/addPositionButtonIcon.svg";
import addInterviewButtonIcon from "./images/addInterviewButtonIcon.svg";
import handleClient from "../../../Utils/client";
import TrackerGuide from "./TrackerGuide";
import BulkUploadModal from "../BulkUpload/BulkUploadModal";
import InterviewBulkModal from "../InterviewBulkUpload/InterviewBulkModal";
import PrescreenBulkModal from "../PrescreenBulkUpload/PrescreenBulkModal";
import FailedUploadModal from "../FailedUpload/FailedUploadModal";

import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import debounce from "lodash/debounce";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from '@mui/material/LinearProgress';
import cogoToast from "cogo-toast";
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CloseIcon from '@mui/icons-material/Close';
import { WebSocketContext } from '../../../WebsocketProvider';


const useStyles = makeStyles(() => ({
  root: {
    "& label.Mui-focused": {
      color: "rgba(0, 0, 0, 0)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0)",
      },
    },
  },
}));

const HeaderNav = ({ tab, renderFilter, showCandidateSearch, debouncedNameToggled, 
  setDebouncedNameToggled,searchName, setSearchName, hideActionButton, handleUrlQueryParam }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { websocketData, websocketStatus } = useContext(WebSocketContext);

  const profileImage = useSelector((state) => state.profile_pic[0]);
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const profile = useSelector((state) => state.profile);
  const clients = useSelector((state) => state.clients);
  const selectedClient = useSelector((state) => state.selected_client);
  const activeTab = tab;
  const [showTrackerGuide, setShowTrackerGuide] = useState(false);
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [interviewBulkUpload, setInterviewBulkUpload] = useState(false);
  const [prescreenBulkUpload, setPrescreenBulkUpload] = useState(false);
  const [isInterviewAllowed, setisInterviewAllowed] = useState(true);
  const [isPrescreeningAllowed, setIsPrescreeningAllowed] = useState(true);
  const [bulkUploadPayload, setBulkUploadPayload] = useState();
  const [bulkStatus, setBulkStatus] = useState({
    status: false,
    data: null,
  });
  const [snackbar, setSnackbar] = useState({ showSnackbar: false, vertical: 'top', horizontal: 'center' });
  const { vertical, horizontal, showSnackbar } = snackbar;
  const [bulkUploadData, setBulkUploadData] = useState();
  const [bulkUploadMsg, setBulkUploadMsg] = useState();
  const [snackbarColor, setSnackbarColor] = useState();
  const [failedUploadModal, setFailedUploadModal] = useState(false);

  useEffect(() => {
    if (!profileImage && profile?.list?.user?.profile_picture) {
      loadImage(profile?.list?.user?.profile_picture, (err, result) => {
        if (result) {
          dispatch(updateProfileImage(result));
        }
      });
    }
  }, [profile?.list?.user?.profile_picture]);

  useEffect(() => {
   console.log(websocketData, websocketStatus)

   setBulkUploadData(websocketData);
   setBulkStatus(websocketStatus);
   
  }, [websocketData, websocketStatus]);

  useEffect(() => {
    if (bulkUploadData) {
      const successInterviewCount = bulkUploadData?.success_interview_count;
      const successPrescreenCount = bulkUploadData?.success_prescreen_count;
      const successApplicationCount = bulkUploadData?.success_application_count;
      const missingInfoCount = bulkUploadData?.missing_info_count;
      const failedApplicationCount = bulkUploadData?.others;
      
      if (bulkUploadData?.failed_reason?.length === 0) {
        if ((bulkUploadData?.success_application_count > 0 || bulkUploadData?.success_interview_count > 0 || bulkUploadData?.success_prescreen_count > 0) && bulkUploadData?.failed_reason?.length === 0) {
          setSnackbarColor("green")
          setBulkUploadMsg((successInterviewCount > 0) ? successInterviewCount + " applications added to Interviews tab"
            : (successPrescreenCount > 0) ? successPrescreenCount + " applications added to Screening tab"
              : (successApplicationCount > 0) ? successApplicationCount + " new applications added" : "")
        }else{
          setSnackbarColor("red")
          setBulkUploadMsg("There is some error occured while uploading!")
        }
        setSnackbar({ showSnackbar: true, vertical: 'top', horizontal: 'center' });
      } else if 
      (bulkUploadData?.failed_reason?.length > 0) {
        let msg = "";
        if(successInterviewCount > 0){
          msg+= `${successInterviewCount}  applications added to Interviews tab, ` 
        }
        if(successPrescreenCount > 0){
          msg+= `${successPrescreenCount} applications added to Screening tab, `
        }
        if(successApplicationCount > 0){
          msg+=  `${successApplicationCount} new applications added, `
        }
        if(missingInfoCount > 0){
          msg+= `${missingInfoCount} applications contain error${(failedApplicationCount > 0) ? "," : ""} `
        }
        if(failedApplicationCount > 0){
          msg+= `${failedApplicationCount} applications failed to upload `
        }
        setBulkUploadMsg(msg);
        setFailedUploadModal(true);
      } else {
        setSnackbarColor("red")
        setBulkUploadMsg("Failed to upload applications")
        setSnackbar({ showSnackbar: true, vertical: 'top', horizontal: 'center' });
      }

    }

  }, [bulkUploadData])

  let roleFlag = false;
  if (handleClient(user?.roles) !== UserRoles?.CLIENT_USER) roleFlag = true;

  const showClientSelect =
    clients?.length > 1 && roleFlag && selectedClient?.id;

  const renderClientSwitch = () => {
    return (
      <>
        <span className="navbar-input-label">Switch Company:</span>
        <ClientSelect />
      </>
    );
  };

  const candidateStateChange = (val) => {
    setDebouncedNameToggled(!val);
  };

  const debounceFetchCandidateData = useCallback(
    debounce(candidateStateChange, 500),
    []
  );

  const setCandidateName = (val) => {
    setSearchName(val);
    handleUrlQueryParam("candidateSearch", val);
    debounceFetchCandidateData(debouncedNameToggled);
  };

  const handleRoute = (route) => {
    window.location.href = route;
  }

  const renderCandidateSearch = () => {
    return (
      <span className="search-bar">

        {'\u2022'}

        <FormControl className="search-bar-wrapper-form">
          <TextField
            value={searchName}
            placeholder="Track candidate by name or email"
            size="small"
            variant="outlined"
            name="candidateName"
            autoComplete="off"
            onChange={(e) => {
              setCandidateName(e.target.value);
            }}
            className={`newTrackerSearchName ${classes.root}`}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className="search-bar-icon"
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </span>

    )
  };

  const handleBulkUploadProceed = (payload) => {
    setBulkUploadPayload(payload)
     setBulkUploadModal(false)
    if(payload?.position?.original?.has_active_pre_screening && isPrescreeningAllowed){
      setPrescreenBulkUpload(true)
    }else{
      setInterviewBulkUpload(true)
    }

  }

  const action = (
    <React.Fragment>
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={()=>handleCloseSnackBar()}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
    </React.Fragment>
  )

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setSnackbar({ showSnackbar: false, vertical: 'top', horizontal: 'center' });
  };

  return (
    <>
    <Snackbar anchorOrigin={{ vertical, horizontal }}
        open={showSnackbar} onClose={handleCloseSnackBar} >
        <SnackbarContent style={{ backgroundColor: snackbarColor, marginTop: "30px", fontWeight: '600' }}
          message={bulkUploadMsg} action={action} />
    </Snackbar>
    {showTrackerGuide && <TrackerGuide setShowTrackerGuide={setShowTrackerGuide} />}
    {bulkUploadModal && 
    <BulkUploadModal bulkUploadModal={bulkUploadModal} 
      setBulkUploadModal={setBulkUploadModal} 
      handleBulkUploadProceed = {handleBulkUploadProceed}
      setisInterviewAllowed= {setisInterviewAllowed}
      setIsPrescreeningAllowed = {setIsPrescreeningAllowed}
    />}
    {interviewBulkUpload && <InterviewBulkModal open={interviewBulkUpload} 
    setInterviewBulkUpload={setInterviewBulkUpload} bulkUploadPayload={bulkUploadPayload} bulkStatus={bulkStatus} 
    setBulkStatus={setBulkStatus} isInterviewAllowed={isInterviewAllowed} setBulkUploadData={setBulkUploadData} />}
    {prescreenBulkUpload && <PrescreenBulkModal open={prescreenBulkUpload} setPrescreenBulkUpload={setPrescreenBulkUpload} 
    bulkUploadPayload={bulkUploadPayload} bulkStatus={bulkStatus} setBulkStatus={setBulkStatus} setBulkUploadData={setBulkUploadData} />}
    {failedUploadModal && <FailedUploadModal open={failedUploadModal} setFailedUploadModal={setFailedUploadModal} 
    bulkUploadData={bulkUploadData} bulkUploadMsg={bulkUploadMsg} />}
    <Box
      className="nav-wrapper"
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography className="active-page">
        {(activeTab==="Add New Position"  ||
         activeTab==="Edit Position") &&
         <IconButton style={{marginRight:"10px"}} onClick={() => history.goBack()} ><ArrowBackIcon /></IconButton>}
          {activeTab}
          {activeTab==="Candidates" &&
          <img src={NewIcon} className="new-icon" onClick={()=>setShowTrackerGuide(true)} alt="new-icon"/>}
          {activeTab === "Add New Candidate" &&
              (bulkStatus?.status === true ?
                <Box className="bulk_upload_box">
                  <span>Candidates uploading.... <span className="bold-600">{bulkStatus?.data?.count}/{bulkStatus?.data?.total}</span></span>
                  <LinearProgress />
                </Box> :
                <Box className="bulk-upload-btn" onClick={() => setBulkUploadModal(true)}>
                  <img src="/images/application-system/cloud-upload.svg" className="upload-icon" alt="upload" /><Typography>Upload in bulk?</Typography>
                </Box>
              )
            }
            {activeTab !== "Add New Candidate" &&
              (bulkStatus?.status === true ?
                <Box className="bulk_upload_box">
                  <span>Candidates uploading.... <span className="bold-600">{bulkStatus?.data?.count}/{bulkStatus?.data?.total}</span></span>
                  <LinearProgress />
                </Box> :
               null
              )
            }
          {showCandidateSearch && renderCandidateSearch()}
        </Typography>
        {showClientSelect && renderClientSwitch()}
        {!hideActionButton &&
        <Box display="flex" alignItems="center">
          <Box
            className={!handleClient(user?.roles) ? "hide-section" : ""}
            display="flex"
            alignItems="center"
          >
            <Button
              variant="outlined"
              className="shortcut-button mr-10"
              onClick={() => handleRoute("/positions/background")}
            >
              <img src={addPositionButtonIcon} className="mr-10" alt="img" />
              <Typography className="text">Add Position</Typography>
            </Button>
            &nbsp;
            <span className="vertical-seperator"></span>
            &nbsp; &nbsp;
          </Box>
          <Button
            variant="outlined"
            className="shortcut-button"
            onClick={() =>handleRoute("/application")}
          >
            <img src={addInterviewButtonIcon} className="mr-10" alt="img" />
            <Typography className="text color-blue">Add Candidate</Typography>
          </Button>
        </Box>
        }
      </Box>
    </Box>
      </>
  );
};

export default HeaderNav;
