import { PositionsResponse } from "../interfaces/Positions";
import axios from "./axios";
import Axios from "axios";
import { POSITION_STATUS } from "../constants/positions";

export const getPositions = (clientId: number,payload={status_list:null}): Promise<PositionsResponse[]> => {
    return axios.post(`/position/all/${clientId}`,payload);
};

export const getPositionTags = (): Promise<Array<String>> => {
  return axios.get("/position/tags/all");
};

export const addPosition = (payload: any) => {
  const apiEndPoint = "/position/create";
  return axios
    .post(apiEndPoint, payload)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      return err.response;
    });
};

export const editPosition = (id: number, payload: any) => {
  const apiEndPoint = `/position/${id}/update`;
  return axios
    .post(apiEndPoint, payload)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      return err.response;
    });
};

export const closePosition = (positionId: number, payload: any) => {
  const apiEndpoint = `/position/${positionId}/${POSITION_STATUS.CLOSED}`;
  return axios.post(apiEndpoint, payload).then((response) => {
    return response;
  });
};

export const openPosition = (positionId: number, payload: any) => {
  const apiEndpoint = `/position/${positionId}/${POSITION_STATUS.OPEN}`;
  return axios.post(apiEndpoint, payload).then((response) => {
    return response;
  });
};

export const add_interview = (interviewPayload: any) => {
  console.log(interviewPayload);
  const apiEndpoint = "/interview/create";
  return axios
    .post(apiEndpoint, interviewPayload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const reschedule_interview = (interviewPayload: any) => {
  console.log(interviewPayload);
  const apiEndpoint = "/interview/reschedule";
  return axios
    .post(apiEndpoint, interviewPayload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const create_test = (testPayload:any) => {
  return axios
    .post("/candidate_pre_screening_round/create", testPayload)
    .then(response => {
      return response;
    }).catch(err => {
      return err.response;
    })
};

export const edit_interview = (id: any, interviewPayload: any) => {
  const apiEndpoint = "/interview/edit/" + id;
  return axios
    .post(apiEndpoint, interviewPayload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err.response;
    });
};

export const getAllInterview = (clientId: any, showMyInterview: any) => {
  const apiEndpoint = "/candidate/all";
  return axios
    .post(apiEndpoint, {
      client_id: clientId,
      show_my_candidates_only: showMyInterview,
    })
    .then((response) => {
      return response.data;
    });
};

export const getHistory = (clientId: string) => {
  const apiEndpoint = "/interview/all";
  return axios
    .post(apiEndpoint, { client_id: clientId })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getAllExperienceBrackets = () => {
  const apiEndpoint = "/position/experience_brackets";
  return axios
    .get(apiEndpoint)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getPositionDetail = (id: any, clientId: any) => {
  const apiEndpoint = "/position/" + id;
  return axios
    .post(apiEndpoint, { client_id: clientId })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getAllRolesRoundCategoriesDict = () => {
  const apiEndpoint = "/role_round_category_resources/v1/dict";
  return axios
    .get(apiEndpoint)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getInterviewDetail = (c_id: any, i_id: any) => {
  const apiEndpoint = "/interview/" + c_id + "/" + i_id;
  return axios
    .get(apiEndpoint)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getRoundHistory = (interviewPayload: any) => {
  const apiEndpoint = "/interview/history";
  return axios
    .post(apiEndpoint, interviewPayload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const accountSettingUpdate = (id: any, interviewPayload: any) => {
  const apiEndpoint = `/client/${id}`;
  return axios
    .post(apiEndpoint, interviewPayload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err?.response || {};
    });
};
export const accountSetting = (id: any) => {
  const apiEndpoint = `/client/${id}`;
  return axios
    .get(apiEndpoint)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err?.response || {};
    });
};

let candidatesV3Source: any = undefined; // Token used to cancel the previous incomplete api call.
export const getInterviewsV3 = (payload: any) => {
  if (candidatesV3Source) {
    candidatesV3Source.cancel();
  }
  const CancelToken = (Axios as any).CancelToken;
  candidatesV3Source = CancelToken.source();
  const apiEndPoint = "/v3/candidate/all";
  return axios
    .post(apiEndPoint, payload, {
      cancelToken: candidatesV3Source.token,
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      if (err.toString() !== "Cancel") {
        console.error(err);
        throw err;
      }
    });
};

export const getMaximumRounds = (clientId: any) => {
  return axios
  .get(`/client/${clientId}/max_round_count`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      console.error(err);
    });
};
export const getPositionStats = (payload: any) => {
  return axios
    .post("/position/stats", payload)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      throw err;
    });
};

export const getRoleRoundCategoriesByGenre = (role_genre: any) => {
  return axios
    .get(`/role_round_category_resources/${role_genre}`)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      return err;
    });
};

export const getSuggestedGuideLink = (payload: any) => {
  return axios
    .post(`/role_round_category_resources/guide-template`, payload)
    .then((resp) => {
      return resp;
    });
};

export const getPrescreeningPosition = (position_id: any,payload={}) => {
    return axios.post(`/position_pre_screening/${position_id}`,payload)
    .then((response) => {
        return response;
    })
    .catch(err => {
        return err.response;
    })
}

export const getPrescreeningTestLink = ( payload: any) => {
    return axios.post(`/candidate_pre_screening_round/re-send-mail`, payload)
    .then((response)=>{
        return response;
    })
    .catch(err => {
        throw err?.response;
    })
}

