import React from 'react';
import Select, {components} from "react-select";

const { Option, SingleValue } = components;

const IconOption = (props) => (
    <Option {...props}>
      <img className="select-icon" src={props.data.image} style={{width: 24, height: 24}} alt="" />
      
      <span>{props.data.label}</span>
    </Option>
);

const ValueOption = (props) => (
    <SingleValue {...props}>
      <img className="select-icon" src={props.data.image} style={{width:24, height: 24}} alt="" />
      
      <span>{props.data.label}</span>
    </SingleValue>
);

const customStyles = {
  control: styles => ({
    ...styles,
    width: '250px',
    minHeight: '34px',
    height: '34px',
    flexWrap: 'nowrap',
    fontSize: '14px'
  }),
  menu: (provided, state) => ({
    ...provided,
    padding: '0 5px',
    fontSize: '14px'
  }),
  option: (provided, state) => ({
    ...provided,
    color: '#212529',
    borderRadius: '4px',
    height: '34px',
    padding: '6px 12px',
    fontSize: '14px',
    cursor: 'pointer'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    padding: '0 5px',
  }),
};

const ImageSelect = ({ options, selected, name, placeholder, onChange }) => {

  return (
      <Select
          className="image-select"
          options={options}
          defaultValue={selected}
          styles={customStyles}
          placeholder={placeholder}
          name={name}
          components={{ Option: IconOption, SingleValue: ValueOption }}
          isSearchable={false}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#b9c7fb',
              primary25: '#DFE6FB',
              primary50: '#DFE6FB',
              primary75: '#DFE6FB',
            },
          })}
          onChange={onChange}
      />
  );
};

export default ImageSelect;
