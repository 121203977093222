import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedClient } from "../../../store/actions/SelectedClient";
import ImageSelect from "../../atoms/ImageSelect/ImageSelect";

const ClientSelect = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const clientList = useSelector((state) => state.clients);
  const selectedClient = useSelector((state) => state.selected_client);
  const clientImages = useSelector((state) => state.client_images);

  let clientOptions = [].concat(
    clientList.map((client) => {
      const image = clientImages[client.id] || "/images/default-logo.png";
      return {
        label: client.client_name,
        value: client.id,
        name: "selectedClient",
        image,
      };
    })
  );

  const selectedOption = clientOptions.find(
    (clientOption) => clientOption.value === selectedClient.id
  );

  const handleClientSelect = (clientOption) => {
    const selected = clientList.find(
      (client) => client.id === clientOption.value
    );
    if (selected) {
      dispatch(updateSelectedClient(selected));
      history.push("/dashboard");
    }
  };

  return (
    <>
      <ImageSelect
        name="selectedClient"
        placeholder="Select Client"
        options={clientOptions}
        selected={selectedOption}
        onChange={handleClientSelect}
      />
    </>
  );
};

export default ClientSelect;
