import { useState } from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Input from "@mui/material/Input";
import RSelect from "react-select";
import styled from "styled-components";
import './style.scss';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import UnsavedModal from "../../Popups/Unsaved/UnsavedModal";
import { useHistory } from "react-router";
import { FORM_TYPE } from "../../constant";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarImg from "../../../../../../assets/images/calendar.svg";
import moment from 'moment';

const FormSelect = styled(RSelect)`
  display: inline-block;
  padding: 0;
  border-radius: 4px;
  width: -webkit-fill-available;
  align-content: center;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.005em;
  color: #3d3d3d;
  > div {
    margin-left: 0;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
  > div > div:nth-child(2) > span {
    display: none;
  }
`;

const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: 'white', // Set a solid background color
      opacity: 1, // Ensure full opacity
      zIndex: 999999, // Ensure the menu is above other content
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white', // Ensure the control has a solid background
    }),
    placeholder: (base) => ({
        ...base,
        fontSize: "13px",
        fontWeight: 400,
    })
};

const RenderSelect = ({ label, isMandatory, formatLabel, ...props }) => {
    return (
        <Box className="text-field-container">
            <Typography className="text">{label}{isMandatory ? <span className="star">*</span> : null}</Typography>
            <Box>
                <FormSelect isSearchable {...props} formatOptionLabel={formatLabel} />
            </Box>
        </Box>
    );
};

const RenderInputField = ({label, isMandatory, ...props}) => {
    return (
        <Box className="text-field-container">
            <Typography className="text">{label}{isMandatory?<span className="star">*</span>:null}</Typography>
            <Box width="100%">
                <Input
                    variant="outlined"
                    className="form-input"
                    disableUnderline
                    {...props}
                />
            </Box>
        </Box>
    )
}

const RenderDateField = ({label, isMandatory, ...props}) => {
    return (
        <Box className="text-field-container">
            <Typography className="text">{label}{isMandatory?<span className="star">*</span>:null}</Typography>
            <Box width="100%" className="date-range-picker-wrapper">
                <DatePicker
                    dateFormat="dd-MM-yyyy"
                    minDate={moment().toDate()}
                    className="dashboard-range-input"
                    {...props}
                />
                <Box className={"dateIcon"}>
                    <img className={"calendarSize"} src={calendarImg} alt="calendar"/>
                </Box>
            </Box>
        </Box>
    );
}

const RenderNumberField = ({label, isMandatory, ...props}) => {
    return (
        <Box className="text-field-container">
            <Typography className="text">{label}{isMandatory?<span className="star">*</span>:null}</Typography>
            <Box width="100%">
                <TextField
                    type="number"
                    fullWidth
                    InputProps={{
                        inputProps: { 
                            min: 0 
                        }
                    }}
                    className='textfield-box'
                    sx={{"& legend": { display: "none" }}}
                    {...props}
                />
            </Box>
        </Box>
    );
}

const AddDriveDetails = ({ driveData, setDriveData, positionList, clientUserList, formType, setUrlQueryParam , isFormDetailsValid}) => {
    const [showCancelModal, setShowCancelModal] = useState(false);
    const history = useHistory();

    const handleNextClick = () => {
        if(isFormDetailsValid()){
            setUrlQueryParam("currentTab", "add-recruiters");
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDriveData({
            ...driveData,
            [name]: value,
        });
    };

    const handleDateChange = (e, name) => {
        setDriveData({
            ...driveData,
            [name]: e,
        });
    }

    const handleDropdownChange = (e, name) => {
        setDriveData({
            ...driveData,
            [name]: e,
        });
    };

    const formatUserOptionLabel = ({ label }) => {
        return (
            <Box fontWeight='500'>{label}</Box>
        )
    };
    const formatPositionOptionLabel = ({ label, internal_name }) => {
        return (
            <Box display="flex">
                <Box fontWeight='500'>{label}</Box>
                {internal_name && <Box className="sub-text">[{internal_name}]</Box>}
            </Box>
        )
    };

    return (
        <Box className='drive-form-container' sx={{p:0}}>
            <Paper elevation={3} sx={{ p: 4, mt: 4, backgroundColor: 'white', borderRadius: '14px' }}>
                <Typography className='heading'>
                    Add Drive Details
                </Typography>
                <hr />
                <form>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <RenderInputField
                                name="driveName"
                                label="Drive Name"
                                placeholder='Enter Drive Name'
                                isMandatory={true}
                                value={driveData.driveName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <RenderDateField
                                id="startDate"
                                name="startDate"
                                label="Start Date"
                                placeholderText='Enter Start Date'
                                isMandatory={true}
                                selected={driveData.startDate}
                                onChange={(e)=> handleDateChange(e, "startDate")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <RenderDateField
                                id="endDate"
                                name="endDate"
                                label="End Date"
                                placeholderText='Enter End Date'
                                isMandatory={true}
                                selected={driveData.endDate}
                                onChange={(e)=> handleDateChange(e, "endDate")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <RenderSelect
                                styles={customStyles}
                                label="Hiring Manager"
                                placeholder='Select Hiring Manager'
                                options={clientUserList}
                                isMandatory={true}
                                onChange={(e) => handleDropdownChange(e, 'hiringManager')}
                                value={driveData.hiringManager}
                                formatLabel={formatUserOptionLabel}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <RenderSelect
                                styles={customStyles}
                                label="TA Manager"
                                placeholder='Select TA Manager'
                                options={clientUserList}
                                isMandatory={true}
                                onChange={(e) => handleDropdownChange(e, 'taManager')}
                                value={driveData.taManager}
                                formatLabel={formatUserOptionLabel}
                            />

                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <RenderSelect
                                styles={customStyles}
                                label="Add Position"
                                options={positionList}
                                placeholder='Select position'
                                isMandatory={true}
                                onChange={(e) => handleDropdownChange(e, 'position')}
                                isDisabled={formType !== FORM_TYPE.ADD}
                                value={driveData.position}
                                formatLabel={formatPositionOptionLabel}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <RenderNumberField
                                name="candidates"
                                label="No. of Candidates to be filled"
                                placeholder='Enter Target Candidates'
                                isMandatory={true}
                                value={driveData.candidates}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                </form>

            </Paper>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 3,
                }}
            >

                <Box>
                    <Button onClick={() => setShowCancelModal(true)} className="close-btn">
                        Cancel
                    </Button>
                </Box>


                <Button onClick={handleNextClick} className="send-btn">
                    <Typography className="text">Next</Typography>
                    <Box>
                        <ArrowRightAltOutlinedIcon className="icon" />
                    </Box>
                </Button>

            </Box>
            <UnsavedModal
                showModal={showCancelModal}
                onClose={() => setShowCancelModal(false)}
                handleStay={() => setShowCancelModal(false)}
                handleYes={() => history.push('/hiring-list')}
            />
        </Box>
    );
};

export default AddDriveDetails;
