// Email Validation Check
export const isValidEmail = (email) => {
    const regexp = /^([a-zA-Z0-9][^\s@]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10})*$/;
      // const regexp = /^([a-zA-Z0-9][a-zA-Z0-9_.+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;
      // const regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    if (!email || email==null) {
        return false;
    } else if (email?.trim()?.length === 0) {
        return false;
    } else if (!regexp.test(email?.trim())) {
        return false;
    }
    return true;
}
