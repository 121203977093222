import { useState } from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import { styled } from '@mui/material/styles';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { ERRORS, RESUME_STATE, SUCCESS } from "../../constant";
import cogoToast from "cogo-toast";
import Dropzone from 'react-dropzone'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { uploadFileV2, uploadResume } from "../../../../../../services/file";
import { isValidEmail } from '../../../../../../Utils/CommonValidations';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './CandidateForm.scss';

const CustomProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  width: 310,
  borderRadius: 7,
  marginTop: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#AEC9EA",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 7,
    backgroundColor: "#1844AD",
  },
}));

const RenderTextField = ({label, isMandatory, ...props}) => {
  return (
      <Box className="modal-text-field-container">
          <Typography className="text">{label}{isMandatory?<span className="star">*</span>:null}</Typography>
          <Box width="100%">
              <TextField
                required={isMandatory}
                fullWidth
                className='textfield-box'
                sx={{"& legend": { display: "none" }}}
                {...props}
              />
          </Box>
      </Box>
  )
}

const RenderContactNumber = ({ showInputField = false, label, prefilled, isMandatory, ...props }) => {
  return (
    <Box className="modal-text-field-container">
      <Typography className="text">{label}{isMandatory ? <span className="star">*</span> : null}</Typography>
      <Box display="flex" gap="12px" alignItems="center">
        <Box width="80%">
          <PhoneInput
            country={'in'}
            className="phone-input"
            inputProps={{
              placeholder: '000-00-000'
            }}
            {...props}
          />
        </Box>
      </Box>
    </Box>
  )
}

const CandidateModal = ({ candidateFormModal, setCandidateFormModal, candidateForm, setCandidateForm, resetCandidateForm, candidateResume, setCandidateResume, resetCandidateResume, candidateList, setCandidateList }) => {
  const [newSkill, setNewSkill] = useState('');

  const handleClose = () => {
    setCandidateForm(resetCandidateForm);
    setCandidateResume(resetCandidateResume);
    setCandidateFormModal({ ...candidateFormModal, isOpen: false, isEdit: false, candidateData: null });
  };

  const handleChange = (e) => {
    setCandidateForm({ ...candidateForm, [e.target.name]: { ...candidateForm[e.target.name], value: e.target.value } })
  }

  const handleContactChange = (contactNo) => {
    setCandidateForm({ ...candidateForm, 'contactNo': { ...candidateForm.contactNo, value: contactNo } })
  }

  const handleValid = () => {
    if (!candidateForm?.firstName?.value || candidateForm?.firstName?.value?.length < 2 || !candidateForm?.firstName?.value?.match(/^[a-z0-9 ]+$/i)) {
      cogoToast.error("Please enter a valid candidate name", {position:'top-right'});
      return false;
    }
    if (!isValidEmail(candidateForm?.emailId?.value)) {
      cogoToast.error("Please enter a valid e-mail id", {position:'top-right'});
      return false;
    }
    if (!candidateForm?.contactNo?.value || candidateForm?.contactNo?.value?.length!==12) {
      cogoToast.error("Please enter a valid contact number", {position:'top-right'});
      return false;
    }
    return true;
  }

  const handleSave = () => {
    if(!handleValid()){
      return;
    }
    const copyCandidateList = [...candidateList];
    const index = candidateFormModal.index;
    if(candidateFormModal.isEdit && candidateFormModal.index >=0 ){
      copyCandidateList[index] = candidateForm ;
    }else{
      copyCandidateList.push(candidateForm)
    }
    setCandidateList(copyCandidateList)
    handleClose();
  }

  const handleAddSkill = () => {
    if (newSkill.trim() !== '') {
      const skills = candidateForm?.skills?.value;
      if(skills.includes(newSkill)){
        cogoToast.error("Skill already present in the list", {position:'top-right'});
      } else {
        skills.push(newSkill);
      }

      setCandidateForm({ ...candidateForm, skills: { ...candidateForm.skills, value: skills } });
      setNewSkill('');
    }
  };

  const handleDeleteSkill = (skillToDelete) => {
    const skills = candidateForm?.skills?.value?.filter((skill) => skill !== skillToDelete)
    setCandidateForm({ ...candidateForm, skills: { ...candidateForm.skills, value: skills } });
  };

  const handleResumeUpload = async (e) => {
    const file = e?.target?.files?.[0] || e[0];
    if (!file || candidateResume.resumeUploadState === RESUME_STATE.UPLOADING) {
      return
    }
    if (!isFileTypeAllowed(file)) {
      cogoToast?.error("File type not allowed, Please select a valid file", { position: "top-right" })
      return;
    }
    setCandidateResume((prev) => ({ ...prev, file }))
    setCandidateResume(prev => ({ ...prev, resumeUploadState: RESUME_STATE.UPLOADING }));
    try {
      // // Uploading file
      const uploadedFileURL = await handleFileUpload(file);

      // // Resume parsing
      const resumeParsed = await handleResumeParse(uploadedFileURL);

      // download file
      // await handleFileDownload(uploadedFileURL); 

      if (resumeParsed === ERRORS.RESUME_PARSE) {
        cogoToast.error("Resume is uploaded but not parsed!");
      } else {
        cogoToast.success(SUCCESS.RESUME_PARSE, { position: "top-right" });
      }
      setCandidateResume(prev => ({ ...prev, resumeUploadState: RESUME_STATE.UPLOADED }));
    }
    catch (error) {
      setCandidateResume(prev => ({ ...prev, resumeUploadState: RESUME_STATE.INITIAL }))
    }
  }

  const handleFileUpload = async (file) => {
    const response = await uploadFileV2(file);
    if (response) {
      const uploadedFileURL = response?.data?.fields["x-amz-meta-internal-key"];
      const uploadedFileName = response?.data?.fields["x-amz-meta-filename"];
      setCandidateResume((prev) => ({ ...prev, uploadedFileName, uploadedFileURL }))
      const copyCandidateForm = { ...candidateForm };
      copyCandidateForm['resume'].value = uploadedFileURL;
      setCandidateForm(copyCandidateForm);
      return uploadedFileURL;
    }
    else {
      cogoToast.error(ERRORS.FILE_UPLOAD, { position: "top-right" })
      throw ERRORS.FILE_UPLOAD;
    }
  }

  const handleResumeParse = async (uploadedFileURL) => {
    const uploadResumeResponse = await uploadResume({ resume_url: uploadedFileURL })
    const apiData = uploadResumeResponse?.data?.data;
    if (uploadResumeResponse?.data?.status === "SUCCESS") {
      handleParsedDetails(uploadResumeResponse?.data?.data);

      setCandidateResume(prev => ({ ...prev, resumeParsedData: apiData, resumeIdentifier: apiData?.resume_identifier }));
      return uploadResumeResponse;
    }
    else {
      cogoToast.error(uploadResumeResponse?.data?.message?.error_detail || uploadResumeResponse?.data || ERRORS.RESUME_PARSE, { position: "top-right" })
      return ERRORS.RESUME_PARSE;
    }
  }

  const handleParsedDetails = (parsedDetails) => {
    const copyCandidateForm = { ...candidateForm };
    if (parsedDetails?.name?.trim()?.length > 0) {
      copyCandidateForm.firstName.value = parsedDetails?.name?.trim();
      copyCandidateForm.firstName.prefilled.status = true;
      copyCandidateForm.firstName.isDisabled = false;
    }
    if (parsedDetails?.emails?.length > 0) {
      copyCandidateForm.emailId.value = parsedDetails?.emails?.[0]?.trim();
      copyCandidateForm.emailId.prefilled.status = true;
      copyCandidateForm.emailId.isDisabled = false;
    }
    if (parsedDetails?.phone_numbers?.length > 0) {
      let tempPhoneNo = parsedDetails?.phone_numbers[0]?.toString()?.length <= 10 ? "91" : "";
      tempPhoneNo = tempPhoneNo + parsedDetails?.phone_numbers[0]?.trim()?.toString();
      copyCandidateForm.contactNo.value = tempPhoneNo;
      copyCandidateForm.contactNo.prefilled.status = true;
    }
    setCandidateForm(copyCandidateForm);
  }

  const isFileTypeAllowed = (file) => {
    let ext = file?.name?.split(".");
    ext = ext?.[ext?.length - 1];
    if (!ext || ext?.length == 0 || (ext?.toLowerCase() !== "pdf" && ext?.toLowerCase() !== "doc" && ext?.toLowerCase() !== "docx")) {
      return false;
    }
    return true;
  }

  const renderResume = () => {
    return <Box className="sub-container">
      <Dropzone onDrop={handleResumeUpload}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <Box {...getRootProps()}>
            {isDragActive && candidateResume.resumeUploadState !== RESUME_STATE.UPLOADING ?
              <Box className="resume-container dropable-container">
                <Typography className="dropable-text">Drop the file here ...</Typography>
              </Box> : <>
                {
                  candidateResume.resumeUploadState === RESUME_STATE.UPLOADED ?
                    <Box className="resume-container resume-uploaded">
                      <Box className="box-1">
                        <Box>
                          <img src="/images/application-system/doc-icon.svg" alt="doc-icon"  width="20px" />
                        </Box>
                        <Typography className="text-1">{candidateResume?.uploadedFileName}</Typography>
                      </Box>
                      <Box>
                        <Box className="candidate-modal-text-content">
                          <Typography className="text-1">Drag & Drop or Browse Files</Typography>
                          <Typography className="text-2">File format PDF/DOC/DOCx - max size upto 2 mb</Typography>
                        </Box>
                      </Box>
                    </Box>
                    :
                    <>
                      <label className="label">
                        <Box className="resume-container">
                          <Box>
                            <img src="/images/application-system/cloud-upload.svg" alt="upload" />
                          </Box>
                          {
                            candidateResume.resumeUploadState === RESUME_STATE.UPLOADING ?
                              <Box className="candidate-modal-text-content">
                                <Typography className="text-1">Uploading, please wait...</Typography>
                                <Box>
                                  <CustomProgress />
                                </Box>
                              </Box>
                              : candidateResume.resumeUploadState === RESUME_STATE.INITIAL ?
                                <Box className="candidate-modal-text-content">
                                  <Typography className="text-1">Drag & Drop or Browse Files</Typography>
                                  <Typography className="text-2">File format PDF/DOC/DOCx - max size upto 2 mb</Typography>
                                </Box>
                                : null
                          }
                        </Box>
                      </label>
                    </>
                }
                <input
                  id="resume"
                  type="file"
                  className="resume-input"
                  onChange={handleResumeUpload}
                  {...getInputProps()}
                />
              </>
            }
          </Box>
        )}
      </Dropzone>
    </Box>
  }

  return (
    <div className='candidate-form-container'>
      <Dialog open={candidateFormModal.isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle fontFamily="Poppins">Candidate Details</DialogTitle>
        <DialogContent className='modal-body'>
          <Box className='resume-text' marginBottom='5px'>Upload Resume to auto fill details</Box>
          <Paper
            variant="outlined"
            sx={{
              borderStyle: 'dashed',
              textAlign: 'center',
              padding: 2,
              mb: 3,
              border: "1px dashed #123FBC",
              backgroundColor: "#EEF2FF",
              borderRadius: "14px",
              cursor: "pointer"
            }}
          >
            {renderResume()}
          </Paper>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <RenderTextField
                label="Full Name"
                placeholder="Enter Full Name"
                isMandatory={true}
                value={candidateForm?.firstName?.value}
                onChange={handleChange}
                name='firstName'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RenderTextField
                label="E-mail ID"
                placeholder="Enter E-mail ID"
                isMandatory={true}
                value={candidateForm?.emailId?.value}
                onChange={handleChange}
                name='emailId'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RenderContactNumber
                label="Contact number"
                showInputField={true}
                name="contactNo"
                isMandatory={true}
                onChange={handleContactChange}
                value={candidateForm?.contactNo?.value}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RenderTextField
                label="LinkedIn Profile"
                placeholder="Enter LinkedIn Profile"
                value={candidateForm?.linkedIn?.value}
                onChange={handleChange}
                name='linkedIn'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RenderTextField
                label="University"
                placeholder="Enter University"
                value={candidateForm?.university?.value}
                onChange={handleChange}
                name='university'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RenderTextField
                label="Current Role"
                placeholder="Enter Current Role"
                value={candidateForm?.currentRole?.value}
                onChange={handleChange}
                name='currentRole'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RenderTextField
                label="Current Company"
                placeholder='Enter Current Company Name'
                value={candidateForm?.currentCompany?.value}
                onChange={handleChange}
                name='currentCompany'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RenderTextField 
                label="Years of Experience"
                placeholder="Enter Years of Experience"
                value={candidateForm?.yearsOfExperience?.value}
                onChange={handleChange}
                type='Number'
                name='yearsOfExperience'
                InputProps={{
                  inputProps: { 
                      min: 0 
                  }
                }}
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Box className='resume-text'>Skills</Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap:'2px' }}>
              {candidateForm?.skills?.value?.map((skill, index) => (
                <Chip
                  key={index}
                  label={skill}
                  onDelete={() => handleDeleteSkill(skill)}
                  deleteIcon={<RemoveCircleOutlineIcon />}
                />
              ))}
              <TextField
                size="small"
                placeholder="Add Skills"
                className='add-skills-textfield-box'
                value={newSkill}
                onChange={(e) => setNewSkill(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleAddSkill();
                  }
                }}
                sx={{ minWidth: 300 , "& legend": { display: "none" }}}
              />
              <Box className='add-skills-button' onClick={handleAddSkill}>
                Add
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className='candidate-details-action'>
          <Box className='cancel-btn' onClick={handleClose}>
            Cancel
          </Box>
          <Box className='add-candidate-btn' onClick={handleSave}>
            Add Candidate
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CandidateModal;
