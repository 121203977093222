import { Container } from "react-bootstrap";
import styled from "styled-components";
import HeaderNav from "../../organisms/HeaderNav";
import Sidebar from "../../organisms/SidebarNav/Sidebar";
import Grid from "@material-ui/core/Grid";
import "../../../styles/common.scss";

interface Props {
  children: React.ReactNode;
}

const MainContainer = styled.div`
  padding-right: 32px;
  // margin-top: 105px;
  margin-top: 96px;
  // height:100vh;
  // overflow-x: hidden;
  // overflow-y: scroll;
  flex: 1;
`;

const PageContainer = styled(Container)`
  padding: 0px;
`;

const SidebarTemplate = ({ children, index, activeTab, showCandidateSearch = false , debouncedNameToggled, 
  setDebouncedNameToggled, searchName, setSearchName, handleUrlQueryParam, hideActionButton = false}: Props) => {
  return (
    <>
      <HeaderNav tab={activeTab} showCandidateSearch={showCandidateSearch} 
      debouncedNameToggled={debouncedNameToggled} 
      setDebouncedNameToggled={setDebouncedNameToggled}
      searchName={searchName}
      setSearchName={setSearchName}
      hideActionButton={hideActionButton}
      handleUrlQueryParam={handleUrlQueryParam}/>
      {/* <hr className="seperator"/> */}
      <PageContainer fluid>
        <Grid container direction="row" className="w-100">
          <Grid
            item
            xs={12}
            sm={3}
            md={2}
            className="pl-0 pr-0 w-100 h-100 sidebar-container"
          >
            <Sidebar index={index} />
          </Grid>
          <Grid item xs={12} sm={9} md={10} className="pl-0 pr-0">
            <MainContainer>{children}</MainContainer>
          </Grid>
        </Grid>
      </PageContainer>
    </>
  );
};

export default SidebarTemplate;
