import { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import queryString from "query-string";
import cogoToast from "cogo-toast";
import moment from "moment";

import SidebarTemplate from "../../../templates/SidebarTemplate/SidebarTemplate";
import handleClient from "../../../../Utils/client";
import Loader from "../../../organisms/Loader/Loader";
import UserRoles from "../../../../constants/UserRoles";

import { downloadResumeUrl } from "../../../../services/file";
import { getPositions } from "../../../../services/positions";
import { addHiringDrive, getDriveById, editHiringDrive, addCandidatesToDrive } from "../../../../services/HiringDrive";
import { getClientUsers } from "../../../../services/ManageUser";
import { uploadFileV2 } from '../../../../services/file';

import Stepper from "./Stepper";
import DriveDetails from "./FormDetails/DriveDetails";
import AddCandidates from "./FormDetails/AddCandidates";
import AddRecruiters from "./FormDetails/AddRecruiters";
import { ERRORS, FORM_TYPE, PREFILLED_INITIAL, RESUME_STATE, STEPPER_STEPS_KEY } from "./constant";
import "./style.scss";
import { WebSocketContext } from '../../../../WebsocketProvider';

const resetCandidateForm = () => {
    return (JSON.parse(JSON.stringify({
        firstName: {
            value: "",
            prefilled: { ...PREFILLED_INITIAL },
            isDisabled: false,
        },
        emailId: {
            value: "",
            prefilled: { ...PREFILLED_INITIAL },
            isDisabled: false,
        },
        contactNo: {
            value: "",
            prefilled: { ...PREFILLED_INITIAL },
            isDisabled: false,
        },
        linkedIn: {
            value: "",
            prefilled: { ...PREFILLED_INITIAL },
            isDisabled: false,
        },
        university: {
            value: "",
            prefilled: { ...PREFILLED_INITIAL },
            isDisabled: false,
        },
        currentRole: {
            value: "",
            prefilled: { ...PREFILLED_INITIAL },
            isDisabled: false,
        },
        currentCompany: {
            value: "",
            prefilled: { ...PREFILLED_INITIAL },
            isDisabled: false,
        },
        yearsOfExperience: {
            value: "",
            prefilled: { ...PREFILLED_INITIAL },
            isDisabled: false,
        },
        skills: {
            value: [],
            prefilled: { ...PREFILLED_INITIAL },
            isDisabled: false,
        },
        resume: {
            value: "",
        },

    })))
}

const resetCandidateResume = () => {
    return (JSON.parse(JSON.stringify({
        file: null,
        uploadedFileURL: null,
        uploadedFileName: null,
        resumeIdentifier: null,
        resumeParsedData: null,
        previewUrl: null,
        resumeUploadState: RESUME_STATE.INITIAL,
    })))
}

const getValidFormType = (type) => {
    type = Object?.values(FORM_TYPE)?.find(item => item === type);
    if (type && type?.length > 0)
        return type;
    return FORM_TYPE.ADD;
}

const DriveForm = () => {
    const { connectWebSocket, triggerSocketAction, isConnected, websocketResponse } = useContext(WebSocketContext);


    const today = new Date().toISOString().split('T')[0];
    const [currentStep, setCurrentStep] = useState({
        key: STEPPER_STEPS_KEY.DRIVE_DETAILS,
        index: 0,
    });
    const [candidateForm, setCandidateForm] = useState(resetCandidateForm());
    const [candidateResume, setCandidateResume] = useState(resetCandidateResume());
    const [candidateList, setCandidateList] = useState([]);
    const [positionList, setPositionList] = useState([
        { label: "Select Position", value: "" },
    ]);
    const [driveData, setDriveData] = useState();
    const [clientUserList, setClientUserList] = useState([]);
    const [bulkUploadFile, setBulkUploadFile] = useState();
    const token = useSelector(state => state?.auth?.access_token);

    const [driveFormData, setDriveFormData] = useState({
        driveName: '',
        startDate: '',
        endDate: '',
        hiringManager: '',
        taManager: '',
        position: '',
        recruiters: [],
        candidates: ''
    });
    const [isMainLoader, setMainLoader] = useState(false);

    /*---------------DriveForm state ------------*/
    const [isLoading, setIsLoading] = useState(false);

    /*--------------------------------------------- */
    const history = useHistory();
    const state = useSelector((state) => state);
    const user = state?.user;
    const isClientIaasEnabled = state?.selected_client?.config_?.iaas?.value;
    const isClientSaasEnabled = state?.selected_client?.config_?.saas?.value;
    const isClientPrescreenEnabled = state?.selected_client?.config_?.is_pre_screening_enabled?.value;
    const isInterviewResumeMandatory = state?.selected_client?.config_?.resume_mandatory?.value;
    const isPrescreenResumeMandatory = state?.selected_client?.config_?.pre_screening_resume_mandatory?.value;
    const isAdmin = handleClient(user?.roles);
    const clientId = state?.selected_client?.id;
    const clientName = state?.selected_client?.client_name;
    const { search } = useLocation();
    const currentTab = queryString?.parse(search)?.currentTab;
    const urlFormType = queryString?.parse(search)?.formType;
    const hiringId = queryString?.parse(search)?.hiringId;
    const [formType, setFormType] = useState(getValidFormType(urlFormType));
    const prod = process.env.REACT_APP_STAGE === "prod" ? true : false;
    const reactAppStage = process.env.REACT_APP_STAGE;

    useEffect(() => {
        if (!isConnected) {
            connectWebSocket();
          }
    }, []);


    useEffect(() => {
    if(websocketResponse){
       setIsLoading(false)   
       history.push(`/hiring-tracker?hiring_id=${driveData?.drive_data?.id || hiringId}`);
    }

    }, [websocketResponse])

    useEffect(() => {
        if (currentTab === STEPPER_STEPS_KEY.ADD_CANDIDATES && hiringId) {
            setCurrentStep({
                key: STEPPER_STEPS_KEY.ADD_CANDIDATES,
                index: 2,
            });
        } else if (currentTab === STEPPER_STEPS_KEY.ADD_RECRUITERS) {
            setCurrentStep({
                key: STEPPER_STEPS_KEY.ADD_RECRUITERS,
                index: 1,
            });
        } else {
            if (currentTab === STEPPER_STEPS_KEY.ADD_CANDIDATES && !hiringId) {
                cogoToast.error('You cannot access add candidates without creating a drive')
            }
            setCurrentStep({
                key: STEPPER_STEPS_KEY.DRIVE_DETAILS,
                index: 0,
            });
        }
    }, [currentTab, hiringId]);

    const structurePostion = (positions) => {
        let tempPositionList = [{ label: "Select Position", value: "" }];
        positions?.map((position) => {
            if (position?.position_status !== 'CLOSED') {
                let obj = {
                    label: position?.position_name,
                    value: position?.id,
                    id: position?.id,
                    internal_name: position?.internal_name,
                    original: position,
                };
                tempPositionList.push(obj);
            }
        });
        setPositionList(tempPositionList);
    };

    const fetchAllPosition = () => {
        getPositions(clientId)
            .then(({ data }) => {
                structurePostion(data);
            })
            .catch((error) => {
                cogoToast.error(
                    error?.data?.msg?.toString() ||
                    error?.data?.toString() ||
                    error?.toString(),
                    {
                        position: "top-right",
                    }
                );
            });
    };

    const getUsers = async () => {
        const resp = await getClientUsers(clientId)
        const clientUserList = resp?.filter((data) => {
            let flag = false;
            Object.keys(data?.user?.roles).map((role) => {
                if (role === UserRoles.CLIENT_USER) {
                    flag = true;
                    data.label = data?.user?.name;
                    data.id = data?.user?.id;
                    data.value = data?.user?.id;
                    data.email = data?.user?.email
                }
            })
            return flag;
        })
        setClientUserList(clientUserList);
    }

    const handleFileDownload = async (uploadedFileURL) => {
        if (!uploadedFileURL) {
            return;
        }
        let response = await downloadResumeUrl(uploadedFileURL);
        if (response) {
            let previewUrl = "";
            if (response?.data?.headers?.content_type !== "application/pdf") {
                previewUrl = response?.data?.url ? `https://docs.google.com/view?url=${encodeURIComponent(response?.data?.url)}&embedded=true` : response?.data || "";
            } else {
                previewUrl = response?.data?.url;
            }
            setCandidateResume((prev) => ({ ...prev, previewUrl }));
            return response;
        }
        else {
            cogoToast.error(ERRORS.FILE_DOWNLOAD, { position: "bottom-right" });
            throw ERRORS.FILE_DOWNLOAD;
        }
    }

    const getResumeNameFromUrl = (url) => {
        const s3Key = queryString?.parse(url)?.s3_key;
        const resumeName = s3Key?.split(/_(.*)/s)[1];
        return resumeName || url;
    }

    const getHiringDetailsById = () => {
        setMainLoader(true);
        getDriveById(hiringId, clientId).then(({ data }) => {
            setMainLoader(false);
            setDriveData(data?.data)
            const hiringDriveData = data?.data
            setDriveFormData({
                ...driveData,
                driveName: hiringDriveData?.drive_data?.name,
                startDate: moment(hiringDriveData?.drive_data?.starting_at * 1000)?.toDate(),
                endDate: moment(hiringDriveData?.drive_data?.ending_at * 1000)?.toDate(),
                hiringManager: { ...hiringDriveData?.drive_data?.hiring_manager, label: hiringDriveData?.drive_data?.hiring_manager?.name, value: hiringDriveData?.drive_data?.hiring_manager?.id },
                taManager: { ...hiringDriveData?.drive_data?.ta_manager, label: hiringDriveData?.drive_data?.ta_manager?.name, value: hiringDriveData?.drive_data?.ta_manager?.id },
                position: { ...hiringDriveData?.position_data, label: hiringDriveData?.position_data?.position_name, value: hiringDriveData?.position_data?.id },
                recruiters: hiringDriveData?.drive_data?.recruiters?.map((data) => { return { ...data, label: data.name } }),
                candidates: hiringDriveData?.drive_data?.target_candidates
            })


        }).catch(error => {
            setMainLoader(false);
            cogoToast.error(
                error?.data?.msg?.toString() ||
                error?.data?.toString() ||
                error?.toString(),
                {
                    position: "top-right",
                }
            );
        });
    }


    const uploadFile = async () => {
        try {
            setIsLoading(true)

            const response = await uploadFileV2(bulkUploadFile);
            const uploadedFileURL = response?.data?.fields["x-amz-meta-internal-key"];
            const current_user = {
                id: user?.id,
                email: user?.email
            };
            let payload = {
                action: "createInterview",
                env: reactAppStage,
                hiring_drive: true,
                hiring_id: hiringId,
                file_name: uploadedFileURL,
                client_id: clientId,
                position_id: driveFormData?.position?.id,
                token: token,
                current_user: current_user
            }
            if (!isConnected) {
                connectWebSocket();
              }
              triggerSocketAction(payload);

        } catch (error) {
            setIsLoading(false)
            cogoToast.error(
                error?.data.msg ||
                error?.data.data?.toString() ||
                error?.data?.toString() ||
                error?.toString() ||
                "File could not be uploaded",
                {
                    position: "top-right",
                }
            );
        }
    };


    const addCandidatesToHiringDrive = () => {
        if(!hiringId){
            cogoToast.success('Hiring drive id is not valid')
            return;
        }
        if (!bulkUploadFile && candidateList?.length === 0) {
            history.push(`/hiring-tracker?hiring_id=${driveData?.drive_data?.id || hiringId}`);
            return;
        }

        if (bulkUploadFile) {
            uploadFile();
            return;
        }

        const candidateListPayload = []
        candidateList?.map((candidateData) => {

            candidateListPayload.push(
                {
                    candidate_json: {
                        full_name: candidateData?.firstName?.value?.trim(),
                        email: candidateData?.emailId?.value?.trim(),
                        contact_number: candidateData?.contactNo?.value,
                        resume_url: candidateData?.resume?.value,
                        skills: candidateData?.skills.value,
                        experience_in_years: candidateData?.yearsOfExperience.value,
                    },
                    client_id: clientId,
                    position_id: driveFormData?.position?.id,
                    comments: null,
                    extra_info: {
                        linkedin: candidateData?.linkedIn?.value,
                    },
                }

            )

        })


        setIsLoading(true);
        let payload = {
            client_id: clientId,
            position_id: driveFormData?.position?.id,
            bulk_data: false, //Will do this once rest flow sets properly
            candidate_data: candidateListPayload
        }

        addCandidatesToDrive(hiringId, payload).then((res) => {
            const data = res?.data;
            setIsLoading(false);
            cogoToast.success('Candidates added to hiring drive successfully')
            history.push(`/hiring-tracker?hiring_id=${driveData?.drive_data?.id || hiringId}`);

        }).catch(error => {
            setIsLoading(false);

            cogoToast?.error(
                error?.data?.msg?.toString() ||
                error?.data?.data?.toString() ||
                error?.data?.toString() ||
                error?.toString(), {
                position: "top-right"
            });

        })
    }

    // Function to get single url query param
    function getUrlQueryParam(paramName) {
        const urlSearchParams = new URLSearchParams(history.location.search);
        return urlSearchParams.get(paramName);
    }

    // Function to set single url query param
    function setUrlQueryParam(paramName, value) {
        const urlSearchParams = new URLSearchParams(history.location.search);
        urlSearchParams.set(paramName, value.toString());
        history.replace(`?${urlSearchParams.toString()}`);
    }

    const isFormDetailsValid = () => {
        const currentDate = new Date();


        if (!driveFormData?.driveName || driveFormData?.driveName?.length === 0 || !driveFormData?.driveName?.match(/^[a-z0-9 ]+$/i)) {
            cogoToast.error("Please enter a valid drive name", { position: 'top-right' });
            return false;
        }

        if (!driveFormData?.startDate) {
            cogoToast.error("Please enter a valid start date", { position: 'top-right' });
            return false;
        }

        if (!driveFormData?.endDate) {
            cogoToast.error("Please enter a valid end date", { position: 'top-right' });
            return false;
        }

        if ((FORM_TYPE.ADD) && (driveFormData?.startDate < today || driveFormData?.endDate < today) ) {
            cogoToast.error("Please enter future date", { position: 'top-right' });
            return false;
        }

        if (driveFormData?.endDate <  driveFormData?.startDate  ) {
            cogoToast.error("End date cannot be less than start date", { position: 'top-right' });
            return false;
        }

        if (!driveFormData?.hiringManager?.id) {
            cogoToast.error("Please select a valid hiring manager", { position: 'top-right' });
            return false;
        }

        if (!driveFormData?.taManager?.id) {
            cogoToast.error("Please select a valid TA manager", { position: 'top-right' });
            return false;
        }

        if (!driveFormData?.position?.id) {
            cogoToast.error("Please select a valid position", { position: 'top-right' });
            return false;
        }


        if (!driveFormData?.candidates) {
            cogoToast.error("Please enter target candidates", { position: 'top-right' });
            return false;
        }

        return true;
    }

    const isHiringDetailsValid = () => {
        let isValidData = true;
        if (!isFormDetailsValid()) {
            isValidData = false;
        }

        return isValidData;
    }


    function epoch(date) {
        return Date.parse(date)
    }

    const handleNextClick = (type) => {
        if (!isHiringDetailsValid() || isLoading) {
            return;
        }

        setIsLoading(true);

        let payload = {
            client_id: clientId,
            name: driveFormData?.driveName,
            position_id: driveFormData?.position?.id,
            hiring_manager: driveFormData?.hiringManager?.id,
            ta_manager: driveFormData?.taManager?.id,
            starting_at: moment(driveFormData?.startDate).unix(),
            ending_at: moment(driveFormData?.endDate).unix(),
            target_candidates: parseInt(driveFormData?.candidates),
            recruiters: driveFormData?.recruiters?.map((data) => { return data?.id }),
        }

        if ( hiringId) {
            payload['updated_at'] = driveData?.drive_data?.updated_at;

            editHiringDrive(hiringId, payload).then((res) => {
                const data = res?.data;
                setDriveData(data?.data);
                cogoToast.success('Hiring drive edited successfully')
                setUrlQueryParam('hiringId', data?.data?.drive_data?.id)
                setUrlQueryParam("currentTab", "add-candidates");
                setIsLoading(false);

            }).catch(error => {
                cogoToast?.error(
                    error?.data?.msg?.toString() ||
                    error?.data?.data?.toString() ||
                    error?.data?.toString() ||
                    error?.toString(), {
                    position: "top-right"
                });

                setIsLoading(false);
            })
        } else {
            addHiringDrive(payload).then((res) => {
                const data = res?.data;
                setDriveData(data?.data);
                cogoToast.success('Hiring drive created successfully')
                setUrlQueryParam('hiringId', data?.data?.drive_data?.id)
                setUrlQueryParam("currentTab", "add-candidates");
                setIsLoading(false);

            }).catch(error => {
                cogoToast?.error(
                    error?.data?.msg?.toString() ||
                    error?.data?.data?.toString() ||
                    error?.data?.toString() ||
                    error?.toString(), {
                    position: "top-right"
                });

                setIsLoading(false);
            })
        }


    }

    /*----------------------------------------------- */

    useEffect(() => {
        if (hiringId) {
            getHiringDetailsById();
        }
    }, [hiringId])

    useEffect(() => {
        if (!urlFormType) {
            /* Reset everything */
            setCandidateForm(resetCandidateForm())
            setCandidateResume(resetCandidateResume());
        }
        setFormType(getValidFormType(urlFormType));
    }, [urlFormType, formType])

    useEffect(() => {
        fetchAllPosition();
        getUsers();
    }, []);

    if (hiringId && isMainLoader === true) {
        return <Loader />
    }
    return (
        <SidebarTemplate hideActionButton={true} activeTab={formType === FORM_TYPE.EDIT ? 'Edit Drive' : 'Create New Drive'}>
            <Box className="hiring-form-details">
                <Stepper
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    formType={formType}
                    setUrlQueryParam={setUrlQueryParam}
                    isFormDetailsValid={isFormDetailsValid}
                    handleNextClick={handleNextClick}
                />

                {currentStep.key === STEPPER_STEPS_KEY.DRIVE_DETAILS ? (
                    <DriveDetails
                        driveData={driveFormData}
                        setDriveData={setDriveFormData}
                        positionList={positionList}
                        clientUserList={clientUserList}
                        formType={formType}
                        setUrlQueryParam={setUrlQueryParam}
                        isFormDetailsValid={isFormDetailsValid}
                    />
                ) : currentStep.key === STEPPER_STEPS_KEY.ADD_RECRUITERS ? (
                    <AddRecruiters
                        driveData={driveFormData}
                        setDriveData={setDriveFormData}
                        clientUserList={clientUserList}
                        formType={formType}
                        handleNextClick={handleNextClick}
                        isLoading={isLoading}

                    />
                ) : currentStep.key === STEPPER_STEPS_KEY.ADD_CANDIDATES ? (
                    <AddCandidates
                        savedDriveData={driveData}
                        candidateForm={candidateForm}
                        setCandidateForm={setCandidateForm}
                        resetCandidateForm={resetCandidateForm}
                        candidateResume={candidateResume}
                        setCandidateResume={setCandidateResume}
                        resetCandidateResume={resetCandidateResume}
                        candidateList={candidateList}
                        setCandidateList={setCandidateList}
                        clientId={clientId}
                        positionId={driveFormData?.position?.id}
                        addCandidatesToHiringDrive={addCandidatesToHiringDrive}
                        bulkUploadFile={bulkUploadFile}
                        setBulkUploadFile={setBulkUploadFile}
                        formType={formType}
                        isLoading={isLoading}
                    />
                ) : null}

            </Box>
        </SidebarTemplate>
    );
};
export default DriveForm;