export const SideBarArray = [{
    name: 'Dashboard',
    path: '/dashboard',
    iconHigh: '/images/sidebar-icons/dashboard-icon-high.svg',
    iconLow: '/images/sidebar-icons/dashboard-icon-low.svg'
},
{
    name: 'Hiring Drive',
    path: '/hiring-list',
    iconHigh: '/images/sidebar-icons/hiring-drive.svg',
    iconLow: '/images/sidebar-icons/hiring-drive.svg'
},
{
    name: 'Candidates',
    path: '/interviews',
    iconHigh: '/images/sidebar-icons/tracker-icon-high.svg',
    iconLow: '/images/sidebar-icons/tracker-icon-low.svg'
},
{
    name: 'Positions',
    path: '/positions',
    iconHigh: '/images/sidebar-icons/pipe-selected.svg',
    iconLow: '/images/sidebar-icons/Pipeline-Unselected.svg'
},
{
    name: 'Reports',
    path: '/history',
    iconHigh: '/images/sidebar-icons/history-icon-high.svg',
    iconLow: '/images/sidebar-icons/history-icon-low.svg'
},
{
    name: 'Settings',
    path: '/settings/',
    iconHigh: '/images/sidebar-icons/settings-icon-high.svg',
    iconLow: '/images/sidebar-icons/settings-icon-low.svg'
},
{
    name: 'Integrations',
    path: '/integration',
    iconHigh: '/images/sidebar-icons/Integration-icon-high.svg',
    iconLow: '/images/sidebar-icons/Integration-icon-low.svg'
},

]
