import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import EastIcon from "@mui/icons-material/East";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Divider from "@mui/material/Divider";
import "./InterviewBulkModal.scss";

import { uploadFileV2 } from '../../../services/file';
import { useSelector } from "react-redux";
import cogoToast from "cogo-toast";
import Select from "react-select";
import CircularProgress from "@mui/material/CircularProgress";

const InterviewBulkModal = ({ open, setInterviewBulkUpload, bulkUploadPayload, bulkStatus, setBulkStatus, isInterviewAllowed, setBulkUploadData }) => {
    const [files, setFiles] = useState();
    const [selectedRound, setSelectedRound] = useState();
    const [loader, setLoader] = useState(false);
    const socket = useRef(WebSocket);

    const user = useSelector(state => state?.user);
    const clientId = useSelector(state => state?.selected_client?.id);
    const token = useSelector(state => state?.auth?.access_token);
    const prod = process.env.REACT_APP_STAGE === "prod" ? true : false;
    const reactAppStage = process.env.REACT_APP_STAGE;


    useEffect(() => {
        // socket.current = new WebSocket(prod ? 'wss://t0rzjb9iel.execute-api.ap-south-1.amazonaws.com/production/' 
        // : 'wss://n9m42x6yk7.execute-api.ap-south-1.amazonaws.com/test');

        socket.current = new WebSocket('wss://t0rzjb9iel.execute-api.ap-south-1.amazonaws.com/production/');
        socket.current.onopen = function (e) {
            console.log("socket on connected");
        };

        socket.current.onclose = function (event) {
            console.log("socket closed", event);
            if (event.wasClean) {
                console.log(
                    `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
                );
            } else {
                console.log(`[close] Connection died; code=${event.code}`);
            }
        };

        socket.current.onerror = function (error) {
            console.log(`[error] ${JSON.stringify(error)}`);
        };

    }, []);

    const uploadFile = async (files) => {

        try {
            const response = await uploadFileV2(files);
            const uploadedFileURL = response?.data?.fields["x-amz-meta-internal-key"];
            const current_user = {
                id: user?.id,
                email: user?.email
            };
            let payload = {
                action: "createInterview",
                file_name: uploadedFileURL,
                client_id: clientId,
                current_user: current_user,
                position_id: bulkUploadPayload?.position?.original?.id,
                round_id: selectedRound?.id || null,
                skip_screening: true,
                token: token,
                env: reactAppStage,
            }

            console.log(payload);
            socket.current.send(JSON.stringify(payload));
            socket.current.onmessage = function ({ data }) {
                let res = JSON.parse(data);
                console.log(res);
                if (res) {
                    setLoader(false);
                    handleClose();
                }

                if (res?.progress) {
                    bulkStatus.status = true;
                    bulkStatus.data = res?.progress;
                    setBulkStatus({ ...bulkStatus });
                }

                if (res?.success_application_count || res?.success_interview_count || res?.success_prescreen_count || res?.failed_reason) {
                    bulkStatus.status = false;
                    bulkStatus.data = "";
                    setBulkStatus({ ...bulkStatus });
                    setBulkUploadData(res);
                }
            }

        } catch (error) {
            setLoader(false);
            cogoToast.error(
                error?.data.msg ||
                error?.data.data?.toString() ||
                error?.data?.toString() ||
                error?.toString() ||
                "File could not be uploaded",
                {
                    position: "top-right",
                }
            );
        }
    };

    const handleClose = () => {
        setInterviewBulkUpload(false);
    }

    const isValidFileUploaded = (file) => {
        const validExtensions = ['csv', 'xlsx', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet']
        const fileExtension = file?.type?.split('/')[1]
        return validExtensions.includes(fileExtension)
    }

    const handleFileUpload = (e) => {
        const file = e?.target?.files[0];
        if (!isValidFileUploaded(file)) {
            cogoToast.error("Please upload a valid file", { position: 'top-right' });
        } else if (file !== undefined) {
            setFiles(e?.target?.files[0]);
        }
        e.target.value = "";
    };

    const handleRemoveFile = () => {
        setFiles(null);
    }

    const handleRoundChange = (e) => {
        setSelectedRound(e)
    }

    const handleSubmit = () => {
        if (files) {
            setLoader(true);
            uploadFile(files);
        } else {
            cogoToast.error("Please choose a file to be uploaded");
        }
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Box className="bulk-upload-modal-container">
                <Box className="main-container">
                    <Box className="title-box">
                        <Box width="100%" height="fit-content">
                            Upload Candidates in Bulk
                        </Box>
                        <Box height="fit-content">
                            <IconButton onClick={handleClose} aria-label="comment">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider />
                    <Box className="body-box">
                        <Box className="upload-content-box">
                            <Box className="content-box">
                                <Box className="body-head">
                                    Upload by candidate details (CSV & XLSX)
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    gap="16px"
                                    alignItems="center"
                                >
                                    {
                                        files?.name && files?.name?.length > 0 ?
                                            <Typography className="file-name">{files?.name}</Typography> : null
                                    }
                                    <Box className={`${!files?.name ? "display-block" : "display-none"}`}>
                                        <label for="file-upload-1">
                                            <Box className="upload-box">
                                                <Typography className="text-1">
                                                    Choose File
                                                </Typography>
                                                <EastIcon />
                                                <input
                                                    type="file"
                                                    className="input-styles"
                                                    id="file-upload-1"
                                                    onChange={(e) => handleFileUpload(e, "input1")}
                                                />
                                            </Box>
                                        </label>
                                    </Box>
                                </Box>
                                {
                                    files?.name?.length > 0 ?
                                        <Box display="flex" gap="20px">
                                            <label for="file-upload-1">
                                                <Typography className="blue-text">Choose New</Typography>
                                            </label>
                                            <Typography className="blue-text" onClick={handleRemoveFile}>Remove</Typography>
                                        </Box>
                                        : null
                                }
                                <Box>
                                    <Typography className="text-2">
                                        To prepare your candidats list in the right format.
                                    </Typography>
                                    <Typography className="text-3">
                                        Please download our{" "}
                                        <a href="https://docs.google.com/spreadsheets/d/1o4TBwoPVxwOPHqkhmaHiup7Ol8KBjuzSyYHJOLJH8R8/edit#gid=0" target="_blank">
                                            <span className="link-2">sample template</span>
                                        </a>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        {isInterviewAllowed &&
                            <Box className="modal-text">
                                Round Name:
                                <Box className="modal-select">
                                    <Select
                                        name="rounds"
                                        options={bulkUploadPayload?.position?.rounds}
                                        onChange={handleRoundChange}
                                        isSearchable
                                        value={selectedRound}
                                        placeholder="Select Round"
                                    />
                                </Box>
                            </Box>
                        }
                        {selectedRound?.value &&
                            <Box className="checkbox-style">
                                <Typography className="text-4">An email will be sent to candidates, asking for preferred interview slots.</Typography>
                            </Box>
                        }

                        <Box display="flex" justifyContent="flex-end" marginTop="16px">
                            <Box display="flex" gap="10px" alignItems="center">
                                <Button className={"proceed-btn"} onClick={handleSubmit}>
                                    {selectedRound?.value ? "Create Interviews" : isInterviewAllowed ? "Upload" : "Send for Approval"}
                                </Button>
                                {loader ? <CircularProgress size="20px" /> : ""}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default InterviewBulkModal;

