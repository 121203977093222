import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Select from "react-select";
import { Input } from "reactstrap";
import TextField from "@mui/material/TextField";
import { isEmail } from "react-multi-email";
import "./BulkUploadModal.scss";
import { getPositions } from "../../../services/positions";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import cogoToast from "cogo-toast";
import { getInterviewApproval } from "../../../services/InterviewApproval";
import CircularProgress from "@mui/material/CircularProgress";

const BulkUploadModal = ({ bulkUploadModal, setBulkUploadModal, handleBulkUploadProceed, setisInterviewAllowed, setIsPrescreeningAllowed }) => {
    const state = useSelector((state) => state);
    const user = state?.user;
    const clientId = state?.selected_client?.id;
    const isHeadhunter = user?.roles?.HEADHUNTER ? true : false;
    const [loader, setLoader] = useState(true);
    const [selectedPostion, setSelectedPostion] = useState();
    const [ccList, setCcList] = useState([]);
    const [newCcEmail, setNewCcEmail] = useState('');
    const [comment, setComment] = useState('')

    const [positionList, setPositionList] = useState([
        { label: "Select Position", value: "" },
    ]);

    const fetchAllPosition = () => {
        getPositions(clientId)
            .then(({ data }) => {
                const positions = data
                let tempPositionList = [{ label: "Select Position", value: "" }];
                positions?.map((position) => {
                    let obj = {
                        label: position?.position_name,
                        value: position?.id,
                        id: position?.id,
                        original: position,
                        rounds: [{ label: "Select Round", value: "" }]
                    };
                    position?.round_details?.map(round => {
                        obj?.rounds?.push({ label: round?.name, value: round?.name, id: round?.id, original: round });
                    });
                    tempPositionList.push(obj);
                });
                setLoader(false);
                setPositionList(tempPositionList);
            })
            .catch((error) => {
                setLoader(false);
                cogoToast.error(
                    error?.data?.msg?.toString() ||
                    error?.data?.toString() ||
                    error?.toString(),
                    {
                        position: "top-right",
                    }
                );
            });
    };

    useEffect(() => {
        fetchAllPosition();
    }, [])

    const triggerInterviewApproval = async () => {

        const headhunterOrgId = user?.hh_org_details?.[0]?.headhunter_org_id;

        try {
            const result = await getInterviewApproval(headhunterOrgId);
            if (result?.status == 200) {
                setisInterviewAllowed(!result?.data?.org_config?.auto_approve ? false : result?.data?.org_config?.auto_approve);
                setIsPrescreeningAllowed(!result?.data?.org_config?.is_allowed_to_create_pre_screening ? false : result?.data?.org_config?.is_allowed_to_create_pre_screening);

            } else {
                cogoToast.error(result?.data);
            }
        } catch (e) {
            cogoToast.error(e?.data?.msg?.toString() || e?.data?.toString() || e?.toString());
        }
    };

    useEffect(() => {
        isHeadhunter && triggerInterviewApproval();
    }, [isHeadhunter]);

    const handlePositionChange = (e) => {
        setSelectedPostion(e);
        const tempCcList = e?.original?.extra_info?.cc_list || [];
        setCcList(tempCcList);
    };

    const handleProceed = () => {
        if (!selectedPostion) {
            cogoToast.error('Please select position details');
            return;
        }

        const payload = {
            position: selectedPostion,
            ccList: ccList,
            comment: comment
        }
        handleBulkUploadProceed(
            payload
        )

    }

    const handleCCEmail = (event) => {
        setNewCcEmail(event?.target?.value);
    };

    const handleKeypress = (e) => {
        if (e?.key === 'Enter') {
            handleAddCCEmail();
        }
    };

    const handleAddCCEmail = () => {
        if (ccList.includes(newCcEmail)) {
            cogoToast.error('This email is already in the CC List')
        } else {
                if (isEmail(newCcEmail?.trim())) {
                    let copyData = [...ccList];
                    copyData.push(newCcEmail?.trim());
                    setCcList(copyData);
                    setNewCcEmail('')
                } else {
                    cogoToast.error("Email is invalid");
                }
            }
        };

    const handleDeleteCCEmail = (index) => {
        let copyData = [...ccList];
        copyData.splice(index, 1);
        setCcList(copyData);
    }
    return (
        <Modal open={bulkUploadModal}>
            <Box className="bulk-upload-modal">
                <Box className="modal-box">
                    <Box className="modal-head">
                        <h1 className="head-text">Upload Candidates in Bulk</h1>
                        <CloseIcon onClick={() => setBulkUploadModal(false)} />
                    </Box>
                    <hr width="100%" />
                    <Box className="body-head">
                        <p>POSITION DETAILS</p>
                    </Box>
                    <Box className="modal-text">
                        Position Name:<span className="red-color">*</span>
                        <Box className="loader-box">
                            <Box className="modal-select">
                                <Select
                                    name="positions"
                                    options={positionList}
                                    isSearchable
                                    onChange={handlePositionChange}
                                    value={selectedPostion}
                                    placeholder="Select Position"
                                />
                            </Box>
                            {loader ? <CircularProgress size="20px" /> : ""}
                        </Box>
                    </Box>

                    <Box className="body-head">
                        <p>ADDITIONAL DETAILS</p>
                    </Box>
                    <Box className="modal-text">
                        Cc List:
                        <Box
                            display="flex"
                            flexDirection="column"
                        >
                            <Box display="flex" className="mt-10">
                                <TextField
                                    name="cc-list"
                                    className="cc-list-input"
                                    variant="outlined"
                                    label
                                    value={newCcEmail}
                                    onChange={handleCCEmail}
                                    onKeyPress={handleKeypress}
                                />
                                <img
                                    src="/images/plus-cc-email.svg"
                                    className="delete-bg ml-2"
                                    onClick={(e) => handleAddCCEmail()}
                                />
                            </Box>
                            <Box className="cc-list-box">
                                {ccList?.map((data, index) => {
                                    return (
                                        <Box
                                            display="flex"
                                            className="mt-10 cc-list-bg"
                                            flexDirection="row"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <label className="cc-list-label w-100">{data}</label>
                                            <img
                                                src="/images/delete-cc-email.svg"
                                                className="delete-bg mr-2"
                                                onClick={(e) => handleDeleteCCEmail(index)}
                                            />
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                    </Box>
                    <Box className="modal-text">
                        Comments (if any):
                        <Box className="modal-select">
                            <Input
                                type="textarea"
                                name="rounds"
                                value={comment}
                                onChange={(e) => setComment(e?.target?.value)}
                            />
                        </Box>
                    </Box>
                    <Box className="modal-button">
                        <Box className="blue-button" onClick={() => handleProceed()} >Next</Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
};

export default BulkUploadModal;
