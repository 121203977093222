import axios from "./axios"

export const addHiringDrive = (payload) => {
    return axios.post(`/hiring-drive/create`,payload).then(res=>{
        return res;
    }).catch(error=>{
        throw error?.response;
    })
}

export const addCandidatesToDrive = (driveId,payload) => {
    return axios.post(`/hiring-drive/${driveId}/application`,payload).then(res=>{
        return res;
    }).catch(error=>{
        return error?.response || error;
    });
}

export const editHiringDrive = (driveId,payload) => {
    return axios.post(`/hiring-drive/${driveId}/edit`,payload).then(res=>{
        return res;
    }).catch(error=>{
        return error?.response || error;
    });
}

export const getDriveById = (driveId,clientId) => {
    return axios.post(`/hiring-drive/${driveId}`, {'client_id': clientId}).then(res=>{
        return res;
    }).catch(error=>{
        throw error?.response || error;
    });
}

export const getHiringDriveByIdAPI = (hiringId,payload={}) => {
    return axios.post(`/hiring-drive/${hiringId}`,payload).then(res=>{
      return res;
    }).catch(error=>{
      throw error?.response || error;
    })
  }

export const getFilterOptionsAPI = (hiringId,payload={}) => {
    return axios.post(`/hiring-drive/${hiringId}/filters`,payload).then(res=>{
      return res;
    }).catch(error=>{
      throw error?.response || error;
    })
  }
  