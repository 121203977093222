import { useState } from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import DeleteIcon from '@mui/icons-material/Delete';
import RSelect from "react-select";
import styled from "styled-components";
import UnsavedModal from "../../Popups/Unsaved/UnsavedModal";
import { useHistory } from "react-router";
import { FORM_TYPE } from "../../constant";
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import CircularProgress from "@mui/material/CircularProgress";
import cogoToast from "cogo-toast";

import './style.scss';

const FormSelect = styled(RSelect)`
  display: inline-block;
  padding: 0;
  border-radius: 4px;
  width: 33%;
  align-content: center;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.005em;
  color: #3d3d3d;
  > div {
    margin-left: 0;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
  > div > div:nth-child(2) > span {
    display: none;
  }
`;


const AddRecruiters = ({ driveData, setDriveData, clientUserList, formType, handleNextClick , isLoading}) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const history = useHistory();

  const handleDropdownChange = (e, name) => {
    const duplicate = driveData.recruiters?.some((recruiter) => recruiter.id === e.id);

    if (duplicate) {
      cogoToast.error('Recruiter already exists.');
    }else{
      setDriveData({ ...driveData, recruiters: [...driveData?.recruiters, e] });
    }

  };

  const handleDeleteRecruiter = (index) => {
    const recruiterList = [...driveData?.recruiters]?.filter((_, i) => i !== index)
    setDriveData({ ...driveData, recruiters: recruiterList });
  };

  const RenderSelect = ({ label, isMandatory, formatLabel, ...props }) => {
    return (
      <Box className="text-field-container">
        <Typography className="text">{label}{isMandatory ? <span className="star">*</span> : null}</Typography>
        <Box>
          <FormSelect isSearchable {...props} formatOptionLabel={formatLabel} />
        </Box>
      </Box>
    );
  };

  const formatUserOptionLabel = ({ user }) => {
    return (
      <Box fontWeight='500'>{user?.name}</Box>
    )
  };

  return (
    <Box className='add-recruiters-form-container'>
      <Paper elevation={3} sx={{ p: 4, mt: 4, backgroundColor: 'white', borderRadius: '14px' }}>
        <Typography className='heading'>
          Add Recruiters
        </Typography>
        <hr />
        <Box display={'flex'} flexDirection='column'>
          <RenderSelect
            label="Add Recruiters"
            placeholder="Select Recruiters"
            options={clientUserList}
            isMandatory={false}
            onChange={(e) => handleDropdownChange(e, 'recruiters')}
            formatLabel={formatUserOptionLabel}
          />

          <Box sx={{ mt: 3 }}>
            <Typography className='recruiter-text'>Added Recruiters</Typography>

            <Box sx={{ display: 'flex', gap: 1 }}>
              {driveData?.recruiters?.length === 0 ? (
                <Typography className="no-recruiters">No Recruiters added</Typography>
              ) : (
                <Grid container spacing={1} className='recruiter-list'>
                  {driveData?.recruiters?.map((recruiter, index) => (
                    <Grid item xs={12} sm={4}> <Box
                      key={index}
                      display='flex'
                      flexDirection='column'
                      sx={{
                        padding: '10px', borderRadius: '8px', border: '1px solid #efefef'
                      }}
                    >

                      <Box display={'flex'} flexDirection='row' justifyContent='space-between' sx={{ marginBottom: '5px' }}>
                        <Typography className='name'>{recruiter?.label}</Typography>
                        <DeleteIcon className='c-pointer' onClick={() => handleDeleteRecruiter(index)} style={{ color: '#BF2929' }} />
                      </Box>

                      <Typography className='name'>{recruiter?.email}</Typography>
                    </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
            <Typography variant="caption" fontSize='13px' color="textSecondary" sx={{ mt: 2 }}>
              Recruiters will have access to this Hiring Drive
            </Typography>
          </Box>
        </Box>

      </Paper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 3,
        }}
      >
        <Box>
          <Button onClick={() => setShowCancelModal(true)} className="close-btn">
            Cancel
          </Button>
        </Box>

        <Button onClick={handleNextClick} className="send-btn">
          <Typography className="text">{formType === FORM_TYPE.EDIT ? 'Edit Drive' : 'Create New Drive'}</Typography>
          <Box height="20px">
                    {
                        isLoading ? 
                        <CircularProgress size="20px" className="loading-icon"/>
                        :  <ArrowRightAltOutlinedIcon className="icon" />
                    }
                    </Box>
          <Box>
           
          </Box>
        </Button>
      </Box>
      <UnsavedModal
        showModal={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        handleStay={() => setShowCancelModal(false)}
        handleYes={() => history.push('/hiring-list')}
      />
    </Box>
  );
};

export default AddRecruiters;
