import { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Divider from "@mui/material/Divider";
import WarningImg from "../../../assets/images/warning-config.svg";

import "./FailedUploadModal.scss";

const failedUploadModal = ({ open, setFailedUploadModal, bulkUploadData, bulkUploadMsg }) => {
    const failedApplicationCount = (bulkUploadData?.missing_info_count || 0) + (bulkUploadData?.others || 0);
    const handleClose = () => {
        setFailedUploadModal(false);
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Box className="failed-upload-modal-container">
                <Box className="main-container">
                    <Box className="title-box">
                        <img src={WarningImg} />
                        <Box width="100%" height="fit-content" marginLeft="10px">
                            Error in {failedApplicationCount} Applications
                        </Box>
                        <Box height="fit-content">
                            <IconButton onClick={handleClose} aria-label="comment">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider />
                    <Box className="body-box">
                        <Box className="body-header">
                            {bulkUploadMsg}
                        </Box>
                        <Box className="reasons-box">
                            {bulkUploadData?.failed_reason?.map((row) => {
                                return (
                                    <span>{row}<br /></span>
                                )
                            })}
                        </Box>
                        <Box display="flex" justifyContent="flex-end" marginTop="16px">
                            <Button className="ok-btn" onClick={handleClose}>
                                OK
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
};

export default failedUploadModal;