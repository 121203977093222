import axios from "./axios";

export const getInterviewApproval = (headhunter_org_id) => {
  const options = {
    headers: {
      'content-type': 'application/json'
    }
  }
  return axios
    .post(`/headhunter_org/${headhunter_org_id}`, options)
    .then((response) => response.data);
};
